import {
  Text,
  Textarea,
  TextInput,
  Title,
  Select,
  Button,
  Stack,
  LoadingOverlay,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  X,
  Calendar,
  MapPin,
  Plus,
  CurrentLocation,
  CloudUpload,
} from 'tabler-icons-react';
import { Ticket } from 'types/Ticket';
import { fetchAreas } from 'Mobile/api/Locations/area';
import { fetchFloors } from 'Mobile/api/Locations/floor';
import { fetchLocations } from 'Mobile/api/Locations/location';
import { Area, Floor, Location } from 'types/Locations';
import { useMutation, useQuery } from 'react-query';
import fetchAllUsers from 'Mobile/api/Users/list';
import { User } from 'types/Users';
import CreateTicket, {
  AddReminder,
  AttachFilesToTicket,
} from 'Mobile/api/Tickets/Create';
import { useStore } from 'Shared/data/Store';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';

interface SelectData {
  value: string;
  label: string;
}

type FormType = Ticket & { files: FileList };

function TicketCreate() {
  const location = useLocation();
  const { register, handleSubmit, control, watch, getValues } =
    useForm<FormType>({
      defaultValues: {
        ...location.state,
        DueDate: location.state?.DueDate,
      },
    });

  const { LocationId, FloorId, files } = watch();
  const navigate = useNavigate();

  const { queryStaleTimeMs } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
  }));

  const locationsQuery = useQuery<Location[], Error, SelectData[]>(
    'locations',
    fetchLocations,
    {
      select: (locations) =>
        locations.map(
          (loc) =>
            ({
              value: loc.LocationId?.toString() ?? '',
              label: loc.Name ?? '',
            } ?? [])
        ),
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of locations failed');
      },
    }
  );

  const floorsQuery = useQuery<Floor[], Error, SelectData[]>(
    ['floors', LocationId],
    () => fetchFloors(LocationId),
    {
      select: (floors) =>
        floors.map(
          (floor) =>
            ({
              value: floor.FloorId?.toString() ?? '',
              label: floor.Name ?? '',
            } ?? [])
        ),
      enabled: !!LocationId,
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of floors failed');
      },
    }
  );

  const areasQuery = useQuery<Area[], Error, SelectData[]>(
    ['areas', LocationId, FloorId],
    () => fetchAreas(LocationId, FloorId),
    {
      select: (areas) =>
        areas.map(
          (area) =>
            ({
              value: area.AreaId?.toString() ?? '',
              label: area.Name ?? '',
            } ?? [])
        ),
      enabled: !!LocationId,
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of areas failed');
      },
    }
  );

  const usersQuery = useQuery<User[], Error, SelectData[]>(
    'users',
    fetchAllUsers,
    {
      select: (users) =>
        [{ value: '0', label: 'All' }].concat(
          users.map((user) => ({
            value: user.Id ?? '',
            label: user.FirstName + ' ' + user.Surname,
          })) ?? []
        ),
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of users failed');
      },
    }
  );

  const mutation = useMutation<any, Error, FormType>(
    'createTicket',
    (ticket) => {
      const { files, ...withoutAttachments } = ticket;
      return CreateTicket(withoutAttachments).then(async (value) => {
        const result = [];
        if (files)
          for (const file of files) {
            result.push(
              AttachFilesToTicket(value, file).catch((err) => {
                showErrorToast(file.name + ' ' + err.Message);
              })
            );
          }
        await Promise.all(result);
        await AddReminder(value, ticket.DueDate, ticket.AssignUserId);
        return value;
      });
    },
    {
      onSuccess: (id) => {
        showOkToast('Ticket has been created');
        navigate('label/' + id);
      },
      onError: () => {
        showErrorToast('Ticket Creation Failed');
      },
    }
  );

  const handleFileCount = (fileUploaded: FileList) => {
    let name = '';
    if (fileUploaded && fileUploaded.length > 0) {
      name = fileUploaded[0].name;
      if (fileUploaded.length > 1) {
        name += ` + ${fileUploaded.length - 1} files`;
      }
    }
    return name;
  };
  return (
    <div className="tickets">
      <LoadingOverlay
        visible={
          mutation.isLoading ||
          usersQuery.isLoading ||
          areasQuery.isLoading ||
          locationsQuery.isLoading ||
          floorsQuery.isLoading
        }
      />
      <div className="flex justify-between items-center">
        <Title>Add new ticket</Title>
        <Link to="/start" className="text-brand inline-flex items-center">
          Close <X />
        </Link>
      </div>
      <Text>Fill the form below to create a new ticket</Text>
      <form
        className="mt-6"
        onSubmit={handleSubmit(
          (data) => mutation.mutate(data),
          (e) => {
            for (const [_, value] of Object.entries(e)) {
              showErrorToast(value.message as string);
            }
          }
        )}
      >
        <Stack>
          <TextInput
            placeholder="Ticket Title"
            {...register('Title', {
              required: 'Title is required',
            })}
          />
          <Textarea
            placeholder="Description"
            {...register('Description', {
              required: 'Description is required',
            })}
          />
          <Controller
            control={control}
            name="LocationId"
            rules={{
              required: 'Location is required',
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                data={locationsQuery.data ?? []}
                placeholder="Enter Location"
                onChange={(value) =>
                  onChange(value ? parseInt(value) : undefined)
                }
                value={value?.toString()}
                onBlur={onBlur}
                icon={<MapPin className="text-brand" />}
                searchable
              />
            )}
          />
          {LocationId && (
            <>
              <Controller
                control={control}
                name="FloorId"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    data={floorsQuery.data ?? []}
                    placeholder="Enter Floors"
                    onChange={(value) =>
                      onChange(value ? parseInt(value) : undefined)
                    }
                    value={value?.toString()}
                    onBlur={onBlur}
                    icon={<CurrentLocation className="text-brand" />}
                    searchable
                  />
                )}
              />
              <Controller
                control={control}
                name="AreaId"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    data={areasQuery.data ?? []}
                    placeholder="Enter Area"
                    onChange={(value) =>
                      onChange(value ? parseInt(value) : undefined)
                    }
                    value={value?.toString()}
                    onBlur={onBlur}
                    icon={<CurrentLocation className="text-brand" />}
                    searchable
                  />
                )}
              />
            </>
          )}

          <Controller
            control={control}
            name="DueDate"
            rules={{
              required: 'Due date is required',
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <DatePicker
                value={value ? new Date(value) : null}
                onChange={(value) =>
                  onChange(value ? new Date(value) : undefined)
                }
                onBlur={onBlur}
                icon={<Calendar className="text-brand" />}
                placeholder="Due date"
                minDate={new Date()}
              />
            )}
          />
          <TextInput
            placeholder="Upload Documents"
            icon={<CloudUpload className="text-brand" />}
            onClick={() =>
              navigate('attach', {
                state: getValues(),
              })
            }
            readOnly
            value={
              files && files.length > 0
                ? handleFileCount(files)
                : 'Upload Documents'
            }
          />
          <Controller
            control={control}
            name="AssignUserId"
            rules={{
              required: 'Assigned user is required',
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                data={usersQuery.data ?? []}
                placeholder="Select assignee"
                onChange={onChange}
                onBlur={onBlur}
                searchable
                value={value?.toString()}
              />
            )}
          />
          <Button
            leftIcon={<Plus />}
            fullWidth
            color="black"
            className="bg-brand mt-10 active:bg-brand"
            type="submit"
          >
            Create ticket
          </Button>
        </Stack>
      </form>
    </div>
  );
}

export default TicketCreate;
