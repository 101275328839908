import config from 'config';

export const refreshToken = async () => {
  const token = localStorage.getItem('refreshTokenString') || '';
  if (token) {
    return await fetch(`${config.apiBaseUrl}/Api/Token/RefreshAccessToken`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token,
      }),
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        const err = await response.json();
        throw err;
      }
    });
  } else {
    throw 'No refresh token';
  }
};

const fetchWithAuth = async (...args: any[]) => {
  const token: string = localStorage.getItem('token') || '';
  const expiryTime: string = localStorage.getItem('expireUCTTime') || '';

  if (
    !token ||
    isNaN(Date.parse(expiryTime)) ||
    new Date() >= new Date(expiryTime)
  ) {
    try {
      await refreshToken().then((data) => {
        localStorage.setItem('token', data.TokenString);
        localStorage.setItem('expireUCTTime', data.ExpireUCTTime);
        localStorage.setItem('refreshTokenString', data.RefreshTokenString);
      });
    } catch (e) {
      localStorage.removeItem('token');
      window.location.href = '/mobile/login';
      return;
    }
  }

  const [resource, config, type] = args;
  if (!config.headers) {
    config.headers = {};
  }
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return fetch(resource, config).then(async (response) => {
    if (response.ok) {
      return type === 'text' ? response.text() : response.json();
    } else {
      const err = await response.json();
      throw err;
    }
  });
};
export default fetchWithAuth;
