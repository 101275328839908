import React from 'react';
import { showNotification } from '@mantine/notifications';
import { AlertCircle, CircleCheck } from 'tabler-icons-react';
import { TicketStatus } from 'types/Tickets';

export const ticketStatusList: { label: string; value: TicketStatus }[] = [
  {
    label: 'Open',
    value: TicketStatus.Open,
  },
  {
    label: 'In Progress',
    value: TicketStatus.InProgress,
  },
  {
    label: 'Complete',
    value: TicketStatus.Completed,
  },
  {
    label: 'Cancelled',
    value: TicketStatus.Cancelled,
  },
];

export function showOkToast(message: string) {
  showNotification({
    message,
    icon: <CircleCheck size={20} />,
    color: 'green',
  });
}

export function showErrorToast(message: string) {
  showNotification({
    message,
    icon: <AlertCircle size={20} />,
    color: 'red',
  });
}
