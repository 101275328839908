import config from 'config';
import buildQuery from 'odata-query';
import fetchWithAuth from 'Shared/helpers/fetch';

const GetTicketAttachments = async (id: number, type: string) => {
  const query = buildQuery({
    filter: {
      Type: type,
      RelatedId: id,
    },
  });
  return fetchWithAuth(
    `${config.apiBaseUrl}/Api/Data/Dto/AttachmentDto${query}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  ).then((data) => data.value);
};

export const getFileUrl = async (img: string) => {
  return await fetchWithAuth(
    `${config.apiBaseUrl}/Api/Attachments/uuid/${img}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    'text'
  ).then((data) => data);
};

export default GetTicketAttachments;
