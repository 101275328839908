import fetchWithAuth from 'Shared/helpers/fetch';
import buildQuery from 'odata-query';
import config from 'config';

export async function fetchAreas(locationId?: number, floorId?: number) {
  let filter: any[] = [{ locationId: locationId }];
  if (floorId) {
    filter = [...filter, { floorId: floorId }];
  }
  const query = buildQuery({
    filter,
  });
  return await fetchWithAuth(`${config.apiBaseUrl}/Api/Areas/odata${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((data) => data.value);
}
