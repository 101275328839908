import Bugsnag from '@bugsnag/js';
import debug from 'debug';
import { StoreMiddleWare } from 'types/Store';

let log: typeof console.log = console.log;
let logStore: typeof console.log = console.log;

const logStyles = {
  error: 'background-color: red; color: white;',
  info: 'background-color: blue; color: white; border-radius: 4px;',
};

if (process.env.NODE_ENV !== 'production') {
  debug.enable('*');
  log = debug('FDS:Error');
  logStore = debug('FDS:Store');
  console.log('');
}

export const storeLoggerMiddleware: StoreMiddleWare =
  (config) => (set, get, api) => {
    return config(
      (...args) => {
        set(...args);
        logStore('%cState updated', logStyles.info);
        console.log({ mutation: args, newState: get() });
      },
      get,
      api,
      []
    );
  };

export function reportError(e: Error) {
  Bugsnag.notify(e);

  if (process.env.NODE_ENV !== 'production') {
    log(`%c${e.message}`, logStyles.error);
  }
}
