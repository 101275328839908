import { Button, Drawer, Title, Text, ActionIcon } from '@mantine/core';
import { ChangeEvent, useRef, useState } from 'react';
import {
  CloudUpload,
  ExclamationMark,
  FileX,
  Folders,
  X,
} from 'tabler-icons-react';

interface UploadProps {
  onClose: () => void;
  children: React.ReactNode;
  title: string;
  description?: string;
}
export default function Modal({
  onClose,
  children,
  title,
  description,
}: UploadProps) {
  return (
    <div className="fixed h-full w-full p-5 bg-gray-100 overflow-auto left-0 top-0 pb-20">
      <div className="flex justify-between items-center">
        <Title>{title}</Title>
        <Button
          className="text-brand inline-flex items-center hover:bg-brand hover:text-white"
          onClick={onClose}
        >
          Close <X />
        </Button>
      </div>
      <Text className="mb-3">{description}</Text>
      {children}
    </div>
  );
}
