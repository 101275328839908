import {
  Text,
  Textarea,
  TextInput,
  Title,
  Select,
  Button,
  Stack,
  LoadingOverlay,
  ActionIcon,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import Upload from 'Mobile/components/UploadModal';
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  X,
  Calendar,
  MapPin,
  Plus,
  CurrentLocation,
  CloudUpload,
  CirclePlus,
  CircleMinus,
} from 'tabler-icons-react';
import { Ticket, TicketStatus } from 'types/Ticket';
import { fetchAreas } from 'Mobile/api/Locations/area';
import { fetchFloors } from 'Mobile/api/Locations/floor';
import { fetchLocations } from 'Mobile/api/Locations/location';
import { Area, Floor, Location } from 'types/Locations';
import { useMutation, useQuery } from 'react-query';
import fetchAllUsers from 'Mobile/api/Users/list';
import { User } from 'types/Users';
import CreateTicket, {
  AddReminder,
  AttachFilesToTicket,
} from 'Mobile/api/Tickets/Create';
import { useStore } from 'Shared/data/Store';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';
import { useState } from 'react';
import fetchTags from 'Mobile/api/Tags/list';
import addEntityTagSingle from 'Mobile/api/Tags/attach';
import { Tags } from 'types/Tags';

interface SelectData {
  value: string;
  label: string;
}

type FormType = Ticket & { files: FileList; Tags: string[] };

function Tickets() {
  const location = useLocation();
  const [openNewAttachments, setOpenNewAttachments] = useState(false);
  const { register, handleSubmit, control, watch, getValues } =
    useForm<FormType>({
      defaultValues: {
        ...location.state,
        DueDate: location.state?.DueDate,
      },
    });
  const { id } = useParams();
  const { LocationId, FloorId, files } = watch();
  const { queryStaleTimeMs } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
  }));

  const navigate = useNavigate();

  const locationsQuery = useQuery<Location[], Error, SelectData[]>(
    'locations',
    fetchLocations,
    {
      select: (locations) =>
        locations.map(
          (loc) =>
            ({
              value: loc.LocationId?.toString() ?? '',
              label: loc.Name ?? '',
            } ?? [])
        ),
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of locations failed');
      },
    }
  );

  const floorsQuery = useQuery<Floor[], Error, SelectData[]>(
    ['floors', LocationId],
    () => fetchFloors(LocationId),
    {
      select: (floors) =>
        floors.map(
          (floor) =>
            ({
              value: floor.FloorId?.toString() ?? '',
              label: floor.Name ?? '',
            } ?? [])
        ),
      enabled: !!LocationId,
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of floors failed');
      },
    }
  );
  const tags = useQuery<Tags[], Error>('labels', fetchTags, {
    onError: () => {
      showErrorToast('Fetching of labels failed');
    },
  });
  const areasQuery = useQuery<Area[], Error, SelectData[]>(
    ['areas', LocationId, FloorId],
    () => fetchAreas(LocationId, FloorId),
    {
      select: (areas) =>
        areas.map(
          (area) =>
            ({
              value: area.AreaId?.toString() ?? '',
              label: area.Name ?? '',
            } ?? [])
        ),
      enabled: !!LocationId,
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of areas failed');
      },
    }
  );

  const usersQuery = useQuery<User[], Error, SelectData[]>(
    'users',
    fetchAllUsers,
    {
      select: (users) =>
        [{ value: '0', label: 'All' }].concat(
          users.map((user) => ({
            value: user.Id ?? '',
            label: user.FirstName + ' ' + user.Surname,
          })) ?? []
        ),
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of users failed');
      },
    }
  );

  const mutation = useMutation<any, Error, FormType>(
    'createTicket',
    (ticket) => {
      const { files, Tags, ...withoutAttachments } = ticket;
      return CreateTicket({
        ...withoutAttachments,
        CheckListId: parseInt(id ?? ''),
        Status: TicketStatus.Open,
      }).then(async (value) => {
        const result = [];
        if (files)
          for (const file of files) {
            result.push(
              AttachFilesToTicket(value, file).catch((err) => {
                showErrorToast(file.name + ' ' + err.Message);
              })
            );
          }
        if (Tags)
          for (const tag of Tags) {
            const id = tags.data?.find((x) => x.TagName === tag)?.Id;
            if (id) result.push(addEntityTagSingle(id, { FDSTicketId: value }));
          }
        await Promise.all(result);
        await AddReminder(value, ticket.DueDate, ticket.AssignUserId);
        return value;
      });
    },
    {
      onSuccess: () => {
        navigate(`/checklists/id/${id}`);
        showOkToast('Ticket has been created');
      },
      onError: () => {
        showErrorToast('Ticket Creation Failed');
      },
    }
  );

  const handleFileCount = (fileUploaded: FileList) => {
    let name = '';
    if (fileUploaded && fileUploaded.length > 0) {
      name = fileUploaded[0].name;
      if (fileUploaded.length > 1) {
        name += ` + ${fileUploaded.length - 1} files`;
      }
    }
    return name;
  };

  return (
    <div className="tickets">
      <LoadingOverlay visible={mutation.isLoading} />
      <div className="flex justify-between items-center">
        <Title>Attach ticket</Title>
        <Link
          to={`/checklists/id/${id}`}
          className="text-brand inline-flex items-center"
        >
          Close <X />
        </Link>
      </div>
      <Text>Fill the form below to create a new ticket</Text>
      <form
        className="mt-6"
        onSubmit={handleSubmit(
          (data) => mutation.mutate(data),
          (e) => {
            for (const [_, value] of Object.entries(e)) {
              showErrorToast(value.message as string);
            }
          }
        )}
      >
        <Stack>
          <TextInput
            placeholder="Ticket Title"
            {...register('Title', {
              required: 'Title is required',
            })}
          />
          <Textarea
            placeholder="Description"
            {...register('Description', {
              required: 'Description is required',
            })}
          />
          <Controller
            control={control}
            name="LocationId"
            rules={{
              required: 'Location is required',
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                data={locationsQuery.data ?? []}
                placeholder="Enter Location"
                onChange={(value) =>
                  onChange(value ? parseInt(value) : undefined)
                }
                value={value?.toString()}
                onBlur={onBlur}
                icon={<MapPin className="text-brand" />}
                searchable
              />
            )}
          />
          {LocationId && (
            <>
              <Controller
                control={control}
                name="FloorId"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    data={floorsQuery.data ?? []}
                    placeholder="Enter Floors"
                    onChange={(value) =>
                      onChange(value ? parseInt(value) : undefined)
                    }
                    value={value?.toString()}
                    onBlur={onBlur}
                    icon={<CurrentLocation className="text-brand" />}
                    searchable
                  />
                )}
              />
              <Controller
                control={control}
                name="AreaId"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    data={areasQuery.data ?? []}
                    placeholder="Enter Area"
                    onChange={(value) =>
                      onChange(value ? parseInt(value) : undefined)
                    }
                    value={value?.toString()}
                    onBlur={onBlur}
                    icon={<CurrentLocation className="text-brand" />}
                    searchable
                  />
                )}
              />
            </>
          )}
          <Controller
            control={control}
            name="DueDate"
            rules={{
              required: 'Duedate is required',
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <DatePicker
                value={value ? new Date(value) : null}
                onChange={(value) =>
                  onChange(value ? new Date(value) : undefined)
                }
                onBlur={onBlur}
                icon={<Calendar className="text-brand" />}
                placeholder="Due date"
                minDate={new Date()}
              />
            )}
          />
          <TextInput
            placeholder="Upload Documents"
            icon={<CloudUpload className="text-brand" />}
            onClick={() =>
              navigate('attach', {
                state: getValues(),
              })
            }
            readOnly
            value={
              files && files.length > 0
                ? handleFileCount(files)
                : 'Upload Documents'
            }
          />
          <Controller
            control={control}
            name="files"
            render={({ field: { onChange } }) => (
              <Upload
                clearAfterSave={false}
                opened={openNewAttachments}
                onClose={() => setOpenNewAttachments(false)}
                onSave={(files) => {
                  onChange(files);
                  setOpenNewAttachments(false);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="AssignUserId"
            rules={{
              required: 'Assigned user is required',
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                data={usersQuery.data ?? []}
                placeholder="Select assignee"
                onChange={onChange}
                onBlur={onBlur}
                value={value?.toString()}
                searchable
              />
            )}
          />
          <div className="flex items-start">
            <span className="mr-3 my-3 font-bold text-lg">Labels</span>
            <ActionIcon
              className="my-3"
              onClick={() =>
                navigate('label', {
                  state: getValues(),
                })
              }
            >
              <CirclePlus className="text-brand" />
            </ActionIcon>
            <Controller
              control={control}
              name="Tags"
              render={({ field: { onChange, value } }) => (
                <>
                  <div className="flex flex-wrap gap-3 ml-3">
                    {value?.map((label) => (
                      <div key={label} className="flex bg-white p-2 rounded">
                        <ActionIcon
                          onClick={() =>
                            onChange(value.filter((x) => x !== label))
                          }
                        >
                          <CircleMinus size={16} className="text-brand" />
                        </ActionIcon>
                        {label}
                      </div>
                    ))}
                  </div>
                </>
              )}
            />
          </div>
          <Button
            leftIcon={<Plus />}
            fullWidth
            color="black"
            className="bg-brand mt-10 active:bg-brand"
            type="submit"
          >
            Finish
          </Button>
        </Stack>
      </form>
    </div>
  );
}

export default Tickets;
