import { Button, LoadingOverlay, Select } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { EditReminder } from 'Mobile/api/Reminders/EditReminder';
import fetchAllUsers from 'Mobile/api/Users/list';
import Modal from 'Mobile/components/Modal';
import SelectData from 'Mobile/helpers/selectData';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from 'Shared/data/Store';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';
import { Calendar } from 'tabler-icons-react';
import { User } from 'types/Users';

interface ReminderProps {
  id: number;
}

export default function Reminder({ id }: ReminderProps) {
  const { reminderId } = useParams();

  const queryClient = useQueryClient();
  const reminderForm = useForm<{ AssignUserId: string; DueDate: string }>();
  const navigate = useNavigate();
  const { queryStaleTimeMs } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
  }));

  const editReminderMutation = useMutation<any, Error, any>(
    'editReminder',
    (reminder) =>
      EditReminder(reminder.reminderId, reminder.dueDate, reminder.assignId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['ticketReminders', id]);
        queryClient.invalidateQueries('tickets');
        navigate('/tickets/id/' + id);
        showOkToast('Edit successful');
      },
      onError: () => {
        showErrorToast('Edit Failed');
      },
    }
  );
  const usersQuery = useQuery<User[], Error, SelectData[]>(
    'users',
    fetchAllUsers,
    {
      select: (users) =>
        [{ value: '0', label: 'All' }].concat(
          users.map((user) => ({
            value: user.Id ?? '',
            label: user.FirstName + ' ' + user.Surname,
          })) ?? []
        ),
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of users failed');
      },
    }
  );
  return (
    <Modal title="Reminder" onClose={() => navigate('/tickets/id/' + id)}>
      <LoadingOverlay visible={editReminderMutation.isLoading} />
      <form
        className="mt-5"
        onSubmit={reminderForm.handleSubmit((e) =>
          editReminderMutation.mutate({
            reminderId,
            dueDate: e.DueDate,
            assignId: e.AssignUserId,
          })
        )}
      >
        <Controller
          control={reminderForm.control}
          name="AssignUserId"
          render={({ field: { onChange, onBlur } }) => (
            <Select
              data={usersQuery.data ?? []}
              placeholder="Select assignee"
              onChange={onChange}
              onBlur={onBlur}
              className="mb-5"
              searchable
            />
          )}
        />
        <Controller
          control={reminderForm.control}
          name="DueDate"
          render={({ field: { onChange, onBlur } }) => (
            <DatePicker
              icon={<Calendar className="text-brand" />}
              placeholder="Due date"
              onChange={(e) => onChange(e?.toISOString())}
              onBlur={onBlur}
            />
          )}
        />
        <Button
          fullWidth
          color="black"
          className="bg-brand mt-10 active:bg-brand"
          type="submit"
        >
          Update Reminder
        </Button>
      </form>
    </Modal>
  );
}
