import fetchWithAuth from 'Shared/helpers/fetch';
import buildQuery from 'odata-query';
import config from 'config';

export async function fetchFloors(locationId?: number) {
  const filter: any[] = [{ locationId: locationId }];
  const query = buildQuery({
    filter,
  });
  return await fetchWithAuth(`${config.apiBaseUrl}/Api/Floors/odata${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((data) => data.value);
}
