enum TicketRestricted {
  All,
  SelfAndAdmin,
}

export enum TypeLineAction {
  TicketCreate = 'TicketCreate',
  DescriptionUpdate = 'DescriptionUpdate',
  StatusUpdates = 'StatusUpdates',
  CommentsCreate = 'CommentsCreate',
  CommentsUpdate = 'CommentsUpdate',
  CommentsDelete = 'CommentsDelete',
  AttachmentUpload = 'AttachmentUpload',
  AttachmentDelete = 'AttachmentDelete',
  TagAdd = 'TagAdd',
  TagRemove = 'TagRemove',
  Others = 'Others',
}

export enum TicketStatus {
  InProgress = 'InProgress',
  Open = 'Open',
  Complete = 'Complete',
  Cancelled = 'Cancelled',
}
export interface TicketTimeLineDto {
  TicketTimeLineId: number;
  CreateUserId: string;
  CreateUserName: string;
  CreatePhone: string;
  CreateEmail: string;
  Action: TypeLineAction;
  CreateDate: string;
  OldValue: string;
  NewValue: string;
  PropertyName: string;
  EntityName: string;
  CreateUser: string;
  CreateUserDelete: boolean;
}

export interface CommentDto {
  CommentId: number;
  Comment: string;
  Attachments: string[];
  CreateUserId: string;
  CreateUserName: string;
  CreatePhone: string;
  CreateEmail: string;
  CreateUser: string;
  CreateUserDelete: boolean;
  CreateDate: string;
  UpdateUserId: string;
  UpdateUserName: string;
  UpdatePhone: string;
  UpdateEmail: string;
  UpdateUser: string;
  UpdateUserDelete: boolean;
  UpdateDate: string;
}
export interface Ticket {
  FDSTicketId: number;
  Access: TicketRestricted;
  Labels: string[];
  Title: string;
  DueDate: string;
  Description: string;
  LocationId: number;
  LocationName: string;
  LocationFullAddress: string;
  CheckListId: number;
  CheckListName: string;
  QuestionId: number;
  Question: string;
  CreateDate: string;
  CreateId: string;
  CreateName: string;
  CreateEmail: string;
  CreatePhone: string;
  CreateUser: string;
  CreateUserDelete: boolean;
  AssignUserId: string;
  AssignId: string;
  AssignName: string;
  AssignEmail: string;
  AssignPhone: string;
  AssignUser: string;
  AssignDelete: boolean;
  Content: string;
  UpdateDate: string;
  TimeLines: TicketTimeLineDto[];
  Comments: CommentDto;
  Attachments: string[];
  Status: TicketStatus;
  FloorId: number;
  FloorName: string;
  AreaId: number;
  AreaName: string;
  Reminders: TicketReminderDTO[];
}

export interface TicketFilterDTO {
  Locations: string[];
  Labels: string[];
  Status: string;
  CreateDate: [Date | null, Date | null];
  DueDate: [Date | null, Date | null];
  Reporter: string;
  Assignee: string;
}

export interface TicketReminderDTO {
  ReminderId: number;
  DueDate: string;
  AssignUser: string;
}
