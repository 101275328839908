import config from 'config';
import fetchWithAuth from 'Shared/helpers/fetch';

const addTag = async (name: string) => {
  return fetchWithAuth(`${config.apiBaseUrl}/Api/Data/FDSTag`, {
    method: 'POST',
    body: JSON.stringify({ TagName: name }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export default addTag;
