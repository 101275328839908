import config from 'config';
import fetchWithAuth from 'Shared/helpers/fetch';
import { Ticket } from 'types/Ticket';

export default async function CreateTicket(ticket: Ticket): Promise<number> {
  return await fetchWithAuth(`${config.apiBaseUrl}/Api/Data/FDSTicket`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...ticket,
      DueDate: new Date(ticket.DueDate ?? '').toUTCString(),
    }),
  }).then((data) => data);
}

export async function AttachFilesToTicket(ticketId: number, file: File) {
  const data = new FormData();
  data.append('', file);
  return fetchWithAuth(
    `${config.apiBaseUrl}/Api/Attachments/Ticket/${ticketId}`,
    {
      method: 'POST',
      body: data,
    }
  ).then((data) => data);
}

export async function AddLabels(ticketId: number, labels: string[]) {
  return await fetchWithAuth(
    `${config.apiBaseUrl}/Api/Data/FDSTicket/${ticketId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ FDSTicketId: ticketId, Labels: labels }),
    }
  ).then((data) => data);
}

export async function AddReminder(
  ticketId: number,
  dueDate: string,
  assignId: string
) {
  return fetchWithAuth(`${config.apiBaseUrl}/Api/Data/FDSReminder`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      AssignUserId: assignId,
      TicketId: ticketId,
      DueDate: new Date(dueDate ?? '').toUTCString(),
      Title: 'Reminder for ticket ' + ticketId,
    }),
  }).then((data) => data);
}
