import fetchWithAuth from 'Shared/helpers/fetch';
import config from 'config';

export async function EditReminder(
  reminderId: number,
  dueDate: string,
  assignId: string
) {
  return fetchWithAuth(
    `${config.apiBaseUrl}/Api/Data/FDSReminder/${reminderId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        AssignUserId: assignId,
        DueDate: new Date(dueDate ?? '').toUTCString(),
      }),
    }
  ).then((data) => data);
}
