import { ActionIcon, Affix, LoadingOverlay, Text, Title } from '@mantine/core';
import Upload from 'Mobile/components/UploadModal';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import AttachFilesToChecklistQuestion from 'Mobile/api/Checklist/attachChecklist';
import { useStore } from 'Shared/data/Store';
import GetTicketAttachments, {
  getFileUrl,
} from 'Mobile/api/Tickets/Attachment';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';
import { FileText, Photo, Plus, X } from 'tabler-icons-react';

interface FileType {
  key: string;
  value: string;
  name: string;
}
export default function AttachmentTab() {
  const [openNewAttachments, setOpenNewAttachments] = useState(false);
  const { queryStaleTimeMs } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
  }));

  const isImage = (fileName: string) => {
    if (
      fileName.endsWith('.jpg') ||
      fileName.endsWith('.jpeg') ||
      fileName.endsWith('.png') ||
      fileName.endsWith('.gif')
    ) {
      return true;
    }
    return false;
  };

  const { id, sectionId, questionId } = useParams();
  const { isLoading, data, refetch } = useQuery<
    {
      images: FileType[];
      files: FileType[];
    },
    Error
  >(
    ['checklistAttachment', questionId],
    () =>
      GetTicketAttachments(
        parseInt(questionId ?? ''),
        'CheckListQuestion'
      ).then(async (files) => {
        const attachments = [];
        for (const file of files) {
          attachments.push(getFileUrl(file.FileUUID));
        }
        const attachments_name = await Promise.all(attachments).then((result) =>
          result.map((x, i) => ({
            value: x,
            key: files[i].FileUUID,
            name: files[i].FileName,
          }))
        );
        return {
          images: attachments_name.filter((x) => isImage(x.key)),
          files: attachments_name.filter((x) => !isImage(x.key)),
        };
      }),
    {
      staleTime: queryStaleTimeMs,
      enabled: !!id,
      onError: () => {
        showErrorToast('Fetching of attachments failed');
      },
    }
  );

  const mutation = useMutation<any, Error, File[]>(
    'appendFiles',
    async (images) => {
      const result = [];
      for (const image of images) {
        result.push(
          AttachFilesToChecklistQuestion(
            parseInt(questionId ?? ''),
            image
          ).catch((err) => {
            showErrorToast(image.name + ' ' + err.Message);
          })
        );
      }
      await Promise.all(result);
    },
    {
      onSuccess: () => {
        showOkToast('Attached files successfully');
        setOpenNewAttachments(false);
        refetch();
      },
      onError: () => {
        showErrorToast('Failed to attach files');
      },
    }
  );

  return (
    <section>
      <LoadingOverlay visible={isLoading || mutation.isLoading} />
      <div className="flex justify-between items-center">
        <Title>Upload files</Title>
        <Link
          to={`/checklists/id/${id}/section/${sectionId}`}
          className="text-brand inline-flex items-center"
        >
          Close <X />
        </Link>
      </div>
      <Text>Select photos or documents</Text>
      <Text color="dimmed" className="flex mt-3 gap-x-2">
        <FileText />
        Documents
      </Text>
      {data?.files.map((x) => (
        <a
          href={x.value}
          key={x.key}
          className="bg-white p-3 mt-2 break-all rounded text-start inline-block w-full"
          download
        >
          {x.name}
        </a>
      ))}
      <Text color="dimmed" className="flex mt-7 gap-x-2">
        <Photo />
        Photos
      </Text>
      <div className="bg-white p-3 rounded grid grid-cols-2 gap-3">
        {data?.images.map((x) => (
          <a href={x.value} download key={x.key}>
            <img src={x.value} />
          </a>
        ))}
      </div>
      <Affix position={{ bottom: 130, right: 35 }}>
        <ActionIcon
          className="bg-brand rounded-full text-white text-center"
          size={60}
          onClick={() => setOpenNewAttachments(true)}
        >
          <Plus size={40} />
        </ActionIcon>
      </Affix>
      <Upload
        clearAfterSave
        opened={openNewAttachments}
        onClose={() => setOpenNewAttachments(false)}
        onSave={(files) => mutation.mutate(files)}
      />
    </section>
  );
}
