import {
  Avatar,
  Button,
  Title,
  Text,
  Input,
  LoadingOverlay,
} from '@mantine/core';
import { GetUser } from 'Mobile/api/Users/Get';
import updateUser from 'Mobile/api/Users/Update';
import { useForm, useFormState } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useStore } from 'Shared/data/Store';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';
import { Logout, Mail, Pencil, User, PencilOff } from 'tabler-icons-react';
import { User as UserType } from 'types/Users';

export default function Settings() {
  const { queryStaleTimeMs, setUserState } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
    setUserState: state.setUserState,
  }));
  const navigate = useNavigate();

  const { isLoading, data, refetch } = useQuery<UserType, Error>(
    ['userDetail'],
    () => GetUser(localStorage.getItem('userId') ?? ''),
    {
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of user detail failed');
      },
      onSuccess: (data) => {
        reset(data);
      },
    }
  );

  const { reset, register, handleSubmit } = useForm<UserType>({
    defaultValues: {
      FirstName: data?.FirstName,
      Surname: data?.Surname,
      Password: '',
    },
  });

  const mutation = useMutation<any, Error, UserType>(
    'updateProfile',
    (user) => {
      return updateUser(localStorage.getItem('userId') ?? '', user);
    },
    {
      onSuccess: () => {
        showOkToast('Profile has been updated');
        refetch();
      },
      onError: () => {
        showErrorToast('Profile update Failed');
      },
    }
  );
  return (
    <div className="pt-32">
      <LoadingOverlay visible={isLoading || mutation.isLoading} />
      <div className="fixed bg-gray-100 z-10 top-0 pt-6 pb-4 left-0 right-0 px-8 border-b-2">
        <div className="flex justify-between items-center">
          <Title>Settings</Title>
          <Button
            variant="light"
            radius="lg"
            className="text-brand bg-white shadow-md"
            size="xs"
            onClick={() => {
              localStorage.clear();
              setUserState({
                isLoggedIn: false,
              });
              navigate('/mobile/login');
            }}
          >
            <Logout className="mr-1" />
            Log out
          </Button>
        </div>
        <span>Edit your account</span>
      </div>
      <div className="bg-white px-4 shadow rounded relative pb-2">
        <Avatar
          radius="xl"
          className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          color="blue"
        >
          {data?.FirstName[0] + ' ' + data?.Surname[0]}
        </Avatar>
        <Text align="center" className="pt-5">
          Account settings
        </Text>
        <form
          className="my-5"
          onSubmit={handleSubmit((data) => {
            mutation.mutate(data);
          })}
        >
          <div>
            <label htmlFor="FirstName">First name</label>
            <Input
              size="lg"
              radius="md"
              className="shadow"
              {...register('FirstName')}
              icon={<User className="text-black" />}
              rightSection={<Pencil className="text-brand" />}
            />
          </div>
          <div className="mb-6">
            <label htmlFor="Surname">Surname</label>
            <Input
              size="lg"
              radius="md"
              className="shadow"
              {...register('Surname')}
              icon={<User className="text-black" />}
              rightSection={<Pencil className="text-brand" />}
            />
          </div>
          <div className="mb-6">
            <label htmlFor="email">E-mail address</label>
            <Input
              readOnly
              size="lg"
              radius="md"
              className="shadow"
              value={data?.Email}
              icon={<Mail className="text-black" />}
              rightSection={<PencilOff className="text-brand" />}
            />
          </div>
          <Button
            fullWidth
            className="bg-brand mt-10 mb-3"
            size="lg"
            type="submit"
          >
            Save Settings
          </Button>
          <Link to="password" className="text-brand text-center block">
            Change Password
          </Link>
        </form>
      </div>
    </div>
  );
}
