import config from 'config';
import fetchWithAuth from 'Shared/helpers/fetch';

export default async function changePassword(
  oldPassword: string,
  newPassword: string,
  confirmPassword: string
) {
  return fetchWithAuth(
    `${config.apiBaseUrl}/Api/Users/changepassword`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ oldPassword, confirmPassword, newPassword }),
    },
    'text'
  ).then((data) => data);
}
