import fetchWithAuth from 'Shared/helpers/fetch';
import { Checklist } from 'types/Checklist';
import config from 'config';

export default async function CreateChecklist(
  checklist: Checklist
): Promise<number> {
  return await fetchWithAuth(`${config.apiBaseUrl}/Api/Data/Checklist`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...checklist }),
  }).then((data) => data);
}
