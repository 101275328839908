import { css } from '@emotion/react';
import { Timeline, Text, Affix, LoadingOverlay, Button } from '@mantine/core';
import { differenceInSeconds } from 'date-fns';
import { useQuery } from 'react-query';
import { useStore } from 'Shared/data/Store';
import { GetTicketTimeLines } from 'Mobile/api/Tickets/Timeline';
import { tailwindConfig } from 'Shared/helpers/styles';
import { FileText, Photo, Plus } from 'tabler-icons-react';
import { TicketTimeLineDto, TypeLineAction } from 'types/Ticket';
import { useNavigate } from 'react-router-dom';

const timelineCss = css`
  & .mantine-Timeline-itemBullet {
    background-color: dimgray;
    border-color: #dee2e6;
  }
  & .mantine-Timeline-itemActive::before {
    border-color: ${(tailwindConfig.theme?.colors as any)?.brand};
  }
  & .mantine-Timeline-itemActive .mantine-Timeline-itemBullet {
    background-color: ${(tailwindConfig.theme?.colors as any)?.brand};
  }
`;

interface TimelineTabProps {
  id: number;
}
type TimelineType = TicketTimeLineDto & {
  NewValues: string[];
};

const isImage = (fileName: string) => {
  if (
    fileName.endsWith('.jpg') ||
    fileName.endsWith('.jpeg') ||
    fileName.endsWith('.png') ||
    fileName.endsWith('.gif')
  ) {
    return true;
  }
  return false;
};

const getTimeLineContent = {
  TicketCreate: (x: TimelineType) => (
    <p>
      <span className="font-extrabold text-lg mr-1">{x.CreateUser}</span>
      created the ticket
    </p>
  ),
  StatusUpdates: (x: TimelineType) => (
    <>
      <p>
        <span className="font-extrabold text-lg mr-1">{x.CreateUser}</span>
        updated the status from <span className="italic">
          {x.OldValue}
        </span> to <span className="italic">{x.NewValue}</span>
      </p>
    </>
  ),
  CommentsCreate: (x: TimelineType) => (
    <>
      <p>
        <span className="font-extrabold text-lg mr-1">{x.CreateUser}</span>
        <div className="bg-white p-3 rounded mt-2 shadow-sm flex items-center gap-x-2">
          {x.NewValue}
        </div>
      </p>
    </>
  ),
  CommentsUpdate: (x: TimelineType) => (
    <>
      <p>
        <span className="font-extrabold text-lg mr-1">{x.CreateUser}</span>
        <div className="bg-white p-3 rounded mt-2 shadow-sm flex items-center gap-x-2">
          {x.NewValue}
        </div>
      </p>
    </>
  ),
  CommentsDelete: (x: TimelineType) => <Text>Deleted a comment</Text>,
  AttachmentUpload: (x: TimelineType) => (
    <>
      <p>
        <span className="font-extrabold text-lg mr-1">{x.CreateUser}</span>
        uploaded a document / documents
      </p>
      {x.NewValues.map((name, i) => (
        <div
          className="bg-white p-3 rounded mt-2 shadow-sm flex items-center gap-x-2"
          key={i}
        >
          <span className="w-5 h-5 text-brand">
            {isImage(name) ? <Photo /> : <FileText />}
          </span>
          <span className="break-all font-bold ">{name}</span>
        </div>
      ))}
    </>
  ),
  DescriptionUpdate: (x: TimelineType) => (
    <>
      <p>
        <span className="font-extrabold text-lg mr-1">{x.CreateUser}</span>
        updated the description to <span className="italic">{x.NewValue}</span>
      </p>
    </>
  ),
  AttachmentDelete: (x: TimelineType) => (
    <>
      <p>
        <span className="font-extrabold text-lg mr-1">{x.CreateUser}</span>
        deleted a file
      </p>
    </>
  ),
  TagAdd: (x: TimelineType) => (
    <>
      <p>
        <span className="font-extrabold text-lg mr-1">{x.CreateUser}</span>
        added a new tag <span className="italic">{x.NewValue}</span>
      </p>
    </>
  ),
  TagRemove: (x: TimelineType) => (
    <>
      <p>
        <span className="font-extrabold text-lg mr-1">{x.CreateUser}</span>
        removed a tag
      </p>
    </>
  ),
  Others: (x: TimelineType) => (
    <>
      <p>
        <span className="font-extrabold text-lg mr-1">{x.CreateUser}</span>
        executed an action
      </p>
    </>
  ),
};

export default function TimelineTab({ id }: TimelineTabProps) {
  const { queryStaleTimeMs } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
  }));
  const navigate = useNavigate();
  const { isLoading, data } = useQuery<TimelineType[], Error>(
    ['ticketTimeline', id],
    () =>
      GetTicketTimeLines(id).then((result) => {
        const processed = [];
        let temp: TicketTimeLineDto | null = null;
        for (const value of result.TimeLines) {
          if (value.Action === TypeLineAction.AttachmentUpload) {
            if (
              temp &&
              temp.CreateUserId === value.CreateUserId &&
              Math.abs(
                differenceInSeconds(
                  new Date(temp.CreateDate),
                  new Date(value.CreateDate)
                )
              ) < 5
            ) {
              processed[processed.length - 1].NewValues.push(value.NewValue);
              temp = value;
            } else {
              temp = value;
              processed.push({
                ...value,
                NewValues: [value.NewValue],
              });
            }
          } else {
            processed.push(value);
            temp = null;
          }
        }
        return processed;
      }),
    {
      staleTime: queryStaleTimeMs,
    }
  );

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <Timeline css={timelineCss} active={1}>
        {data?.map((x) => (
          <Timeline.Item active key={x.TicketTimeLineId}>
            <Text color="dimmed">
              {new Date(x.CreateDate).toLocaleString()}
            </Text>
            {getTimeLineContent[x.Action](x)}
          </Timeline.Item>
        ))}
      </Timeline>
      <Affix position={{ bottom: 130, right: 35 }}>
        <Button
          className="bg-brand focus:bg-brand"
          size="lg"
          radius="md"
          onClick={() => navigate(`/tickets/id/${id}/comment`)}
        >
          <Plus size={40} />
          Add Comment
        </Button>
      </Affix>
    </>
  );
}
