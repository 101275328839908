import config from 'config';
import fetchWithAuth from 'Shared/helpers/fetch';

const addEntityTagSingle = async (
  tagId: number,
  data: { CheckListId?: number; FDSTicketId?: number }
) => {
  return fetchWithAuth(`${config.apiBaseUrl}/Api/Data/FDSEntityTag/-1`, {
    method: 'POST',
    body: JSON.stringify({ TagId: tagId, ...data }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export default addEntityTagSingle;
