import create from 'zustand';
import { GlobalState, UserState, StateCreator } from 'types/Store';
import { assocPath, mergeDeepRight } from 'ramda';
import { defaultChecklistFilters } from 'Shared/data/Checklists';
import { devtools, persist } from 'zustand/middleware';
import { storeLoggerMiddleware } from 'Shared/helpers/logging';

/**
 * Curried functions for immutable updates
 */

const updateChecklistFilters = assocPath<any, GlobalState>([
  'checklists',
  'listFilters',
]);

const updateUserState = assocPath<UserState, GlobalState>(['user']);

const state: StateCreator = (set) => ({
  checklists: {
    listFilters: defaultChecklistFilters,
    queryStaleTimeMs: 30 * 1000,
  },
  members: {
    queryStaleTimeMs: 30 * 1000,
  },
  user: {
    userId: '',
    isLoggedIn: false,
    isAdmin: false,
  },
  applyChecklistFilters(filters: any) {
    set((state) => updateChecklistFilters(filters, state));
  },
  setUserState(user: UserState) {
    set((state) => updateUserState(user, state));
  },
});

export const useStore = create(
  storeLoggerMiddleware(
    devtools(
      persist(state as any, {
        name: 'fdsAdminState',
        version: 1,
      }) as any
    )
  )
);
