import config from 'config';
import buildQuery from 'odata-query';
import fetchWithAuth from 'Shared/helpers/fetch';

export const fetchTicketTags = async (TicketId?: number) => {
  if (TicketId) {
    const query = buildQuery({
      filter: [{ TicketId: TicketId }],
    });
    return fetchWithAuth(`${config.apiBaseUrl}/Api/EntityTags/odata${query}`, {
      method: 'GET',
    }).then((data) => data.value);
  }
};
