import React, { ReactElement } from 'react';
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { AppType } from 'types/App';
import 'App.css';

interface AppProps {
  type: AppType;
  appRoutes: ReactElement;
}

function App({ appRoutes }: AppProps) {
  return (
    <MantineProvider theme={{}}>
      <NotificationsProvider position="bottom-center">
        <div className={'h-screen bg-gray-100 overflow-y-scroll'} id="view">
          {appRoutes}
        </div>
      </NotificationsProvider>
    </MantineProvider>
  );
}

export default App;
