import { Route, Routes } from 'react-router-dom';
import Reminders from './Reminders';

export default function Index() {
  return (
    <Routes>
      <Route index path="" element={<Reminders active />} />
      <Route path="active" element={<Reminders active />} />
      <Route path="active/:id" element={<Reminders active />} />
      <Route path="passed" element={<Reminders />} />
      <Route path="passed/:id" element={<Reminders />} />
    </Routes>
  );
}
