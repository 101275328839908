import { css } from '@emotion/react';
import { CSSProperties } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
import logo from 'Shared/assets/images/logo-fds.png';

export const tailwindConfig = resolveConfig(tailwindCustomConfig);

export function makeLineClamp(lineClamp = 3): CSSProperties {
  return {
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitLineClamp: lineClamp,
    lineClamp: lineClamp,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
  };
}

export const confirmModalCss = css`
  backdrop-filter: blur(6px);
  .mantine-Modal-overlay {
    opacity: 0.55;
  }
  .mantine-Paper-root {
    background-color: rgba(260, 260, 260, 1);
    box-shadow: 0px 8px 20px rgba(31, 30, 30, 0.33);
  }
  .mantine-Modal-body {
    form {
      padding: 0 25px;
      background-color: rgba(260, 260, 260, 1);
    }
  }
  .mantine-ActionIcon-root {
    svg {
      height: 24px;
      width: 24px;
      stroke: ${(tailwindConfig.theme.backgroundColor as any)?.brand};
    }
  }
  .mantine-Paper-root {
    min-width: 600px;
    min-height: 455px;
    width: unset;
    & h1 {
      font-weight: 500;
      font-size: 40px;
      line-height: 53px;
      text-align: center;
    }
  }
  .mantine-TextInput-root {
    width: 100%;
  }
  .mantine-Button-outline {
    border-color: ${(tailwindConfig.theme.backgroundColor as any)?.brand};
    color: ${(tailwindConfig.theme.backgroundColor as any)?.brand};
  }
`;

export const thCss = css`
  font-size: 1em !important;
  font-weight: bold !important;
  top: 100px;
  text-align: center !important;
  &:first-of-type {
    text-align: unset !important;
  }
`;

export const tdCss = css`
  padding: 0 !important;
`;

export const customScrollXCss = css`
  ::-webkit-scrollbar {
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    background: ${(tailwindConfig.theme.backgroundColor as any)?.greyDark};
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(tailwindConfig.theme.backgroundColor as any)?.brand};
    max-height: 10px;
    border-radius: 8px;
  }
`;

export const customScrollYCss = css`
  ${customScrollXCss}
  ::-webkit-scrollbar {
    width: 4px;
  }
`;

export const previewModalCss = css`
  .mantine-Modal-inner {
    display: flex;
    align-items: center;

    .mantine-Modal-body {
      display: flex;
      justify-content: center;
    }

    .mantine-Modal-modal {
      width: 1000px;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    & svg {
      stroke: ${(tailwindConfig.theme.backgroundColor as any)?.brand};
    }
  }
`;

export const carouselModalCss = css`
  .mantine-Modal-inner {
    padding-top: 15px;
    .mantine-Paper-root {
      width: 100%;
      height: 100%;
      background: transparent;
    }
    .mantine-Modal-body {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .mantine-ActionIcon-root {
      & svg {
        height: 30px;
        width: 30px;
        stroke: ${(tailwindConfig.theme.backgroundColor as any)?.brand};
      }
    }
  }
`;

export const nameCss = css`
  box-shadow: 0px 0px 20px rgba(31, 30, 30, 0.08);
  height: 48px;
  background-color: #ffffff;
`;

export const actionsCss = css`
  svg {
    stroke: ${(tailwindConfig.theme.backgroundColor as any)?.brand};
  }
`;

export const inputCss = css`
  input {
    height: 46px;
    background: ${(tailwindConfig.theme.backgroundColor as any)?.greyLight};
  }
  .mantine-DatePicker-rightSection {
    margin-right: 13px;
    rect {
      color: ${(tailwindConfig.theme.backgroundColor as any)?.brand};
    }
    line {
      color: ${(tailwindConfig.theme.backgroundColor as any)?.brand};
    }
  }
`;

export const inputTimeCss = css`
  .mantine-TimeInput-input {
    height: 46px;
    background: ${(tailwindConfig.theme.backgroundColor as any)?.greyLight};
  }
  .mantine-TimeInput-controls {
    height: 44px;
  }
  .mantine-TimeInput-rightSection {
    margin-right: 13px;
  }
`;
export const bigInputTimeCss = css`
  ${inputTimeCss}
  .mantine-TimeInput-input {
    height: 60px;
  }
  .mantine-TimeInput-controls {
    height: 58px;
  }
`;

export const textAreaCss = css`
  textarea {
    height: 100px;
    background: ${(tailwindConfig.theme.backgroundColor as any)?.greyLight};
  }
`;

export const ticketCss = css`
  box-shadow: 0px 0px 16px -5px rgba(80, 75, 75, 0.15);
  margin: 4px 4px 6px;
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -2px;
    width: 0px;
    height: 2px;
    background: ${(tailwindConfig.theme.backgroundColor as any)?.brand};
    transition: all 0.45s;
  }
  &:hover:after {
    width: 100%;
    left: 0;
  }
  & button {
    svg {
      stroke: ${(tailwindConfig.theme.backgroundColor as any)?.brand};
    }
  }
`;

export const avatarCss = css`
  .mantine-Avatar-placeholder {
    background-color: ${(tailwindConfig.theme.backgroundColor as any)?.orange};
    color: white;
    font-weight: 400 !important;
    font-size: 11px;
  }
`;

export const serachCss = css`
  width: 100% !important;
  display: flex;
  justify-content: flex-end;
  .mantine-TextInput-wrapper {
    width: 100%;
    max-width: 550px;
  }
  input {
    max-width: 550px;
  }
`;

export const carouselCss = css`
  button {
    background-color: ${(tailwindConfig.theme.backgroundColor as any)?.brand};
    box-shadow: none;
  }
  button:focus {
    box-shadow: 0 0 1px 3px
      ${(tailwindConfig.theme.backgroundColor as any)?.brand};
  }
  .rec-dot_active {
    box-shadow: 0 0 1px 3px
      ${(tailwindConfig.theme.backgroundColor as any)?.brand};
  }
  .rec-carousel {
    justify-content: center;
    .rec-slider-container {
      max-width: 260px;
    }
  }
`;

export const filterTitelCss = css`
  .mantine-Badge-rightSection {
    width: 40%;
    display: flex;
    justify-content: end;
    svg {
      color: ${(tailwindConfig.theme.backgroundColor as any)?.brand} !important;
    }
  }
`;

export const filterSelectCss = css`
  .mantine-Select-rightSection {
    svg {
      width: 30px;
      height: 30px;
      color: ${(tailwindConfig.theme.backgroundColor as any)?.brand} !important;
    }
  }
`;

export const cancelButtonCss = css`
  .mantine-Button-inner {
    justify-content: flex-start;
    .mantine-Button-leftIcon {
      width: 35%;
    }
  }
`;

export const logoCss = css`
  background: url(${logo}) no-repeat center center / cover;
  height: 60px;
`;

export const printLogoCss = css`
  background: url(${logo}) no-repeat center center / cover;
  height: 94px;
  width: 131px;
`;

export const buttonIconClasses =
  'bg-brand rounded-[50%] text-white w-7 h-7 flex items-center justify-center cursor-pointer';

export const rowClasses = 'flex mt-2.5 px-6 bg-white h-12';

export const thClass = 'sticky z-10 bg-white';

export const tagClasses =
  'bg-gray-100 text-black text-xs font-normal normal-case';

export const ticketClasses =
  'block bg-white hover:bg-gray-100 flex flex-col rounded-md border border-white drop-shadow min-w-[450px] pb-3';
