import {
  Image,
  Text,
  TextInput,
  Button,
  PasswordInput,
  LoadingOverlay,
} from '@mantine/core';
import logo from 'Shared/assets/images/fds-logo-dark.png';
import { Lock, User } from 'tabler-icons-react';
import { useForm } from 'react-hook-form';
import { UserLoginFields } from 'types/Users';
import { useMutation } from 'react-query';
import { mobileLogin } from 'Mobile/api/Login';
import { showOkToast } from 'Shared/helpers/ui';
import { useStore } from 'Shared/data/Store';
import { useNavigate, useLocation } from 'react-router-dom';

function Login() {
  const { register, handleSubmit } = useForm<UserLoginFields>();
  const setUserState = useStore((state) => state.setUserState);
  const navigate = useNavigate();
  const location = useLocation();
  const from =
    (location.state as Record<string, any>)?.from?.pathname || '/mobile';

  const mutation = useMutation<any, Error, UserLoginFields>(
    'mobileLogin',
    (details) => mobileLogin({ ...details }),
    {
      onSuccess: (data) => {
        localStorage.setItem('token', data.TokenString);
        localStorage.setItem('expireUCTTime', data.ExpireUCTTime);
        localStorage.setItem('refreshTokenString', data.RefreshTokenString);
        localStorage.setItem('userId', data.UserId);
        setUserState({
          isLoggedIn: true,
          isAdmin: data.Roles.includes('Admin'),
        });
        showOkToast('Successfully Logged In');
        navigate(from);
      },
      onError: () => {
        showOkToast('Error Logging In');
      },
    }
  );
  return (
    <div className="flex items-center flex-col p-8 justify-center">
      <LoadingOverlay visible={mutation.isLoading} />
      <Image src={logo} width={156} height={125} />
      <Text className="font-extrabold text-4xl text-center mb-3">
        Login to access your account
      </Text>
      <Text>Enter your login data below</Text>
      <form
        className="mt-8"
        onSubmit={handleSubmit((data) => mutation.mutate(data))}
      >
        <TextInput
          placeholder="Username"
          icon={<User />}
          size="xl"
          {...register('username', {
            required: true,
          })}
        />
        <PasswordInput
          placeholder="Password"
          icon={<Lock />}
          size="xl"
          className="mt-3"
          {...register('password', {
            required: true,
          })}
        />
        <Button
          fullWidth
          color="black"
          className="bg-brand mt-10 active:bg-brand hover:bg-brand"
          type="submit"
          size="lg"
        >
          Login
        </Button>
      </form>
    </div>
  );
}

export default Login;
