import config from 'config';
import buildQuery from 'odata-query';
import fetchWithAuth from 'Shared/helpers/fetch';

export async function GetTicket(filter: {
  FDSTicketId?: number;
  CheckListId?: number;
}) {
  const query = buildQuery({
    filter: [filter],
    select: [
      'FDSTicketId',
      'Status',
      'Title',
      'Description',
      'CreateName',
      'AssignName',
      'CreateEmail',
      'CreatePhone',
      'AssignEmail',
      'AssignPhone',
      'LocationName',
      'FloorName',
      'AreaName',
      'CreateDate',
      'LocationId',
      'AssignId',
      'FloorId',
      'AreaId',
      'CreateId',
      'CheckListId',
    ],
    orderBy: ['DueDate'],
  });
  return fetchWithAuth(`${config.apiBaseUrl}/Api/Tickets/odata${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((data) => data.value[0]);
}
