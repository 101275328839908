import config from 'config';
import fetchWithAuth from 'Shared/helpers/fetch';

export const removeEntityTagSingle = async (tagId: number) => {
  return fetchWithAuth(
    `${config.apiBaseUrl}/Api/Data/FDSEntityTag/${tagId}`,
    {
      method: 'DELETE',
    },
    'text'
  );
};
