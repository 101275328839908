import Upload from 'Mobile/components/UploadModal';
import { useNavigate, useLocation } from 'react-router-dom';

export default function AttachFiles() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Upload
      files={location.state?.files}
      onSave={(files) => {
        navigate('/start/ticket', {
          state: {
            ...location.state,
            files,
          },
        });
      }}
      opened={true}
      onClose={() => {
        navigate('/start/ticket', { state: location.state });
      }}
      clearAfterSave={false}
    />
  );
}
