import { Link } from 'react-router-dom';
import { SimpleGrid, Title } from '@mantine/core';
import { ListCheck, Ticket } from 'tabler-icons-react';
function Start() {
  return (
    <div className="pt-12">
      <Title className="text-6xl text-center">Start</Title>
      <SimpleGrid cols={2} className="mt-28">
        <Link
          to={`ticket`}
          className="rounded bg-white shadow-xl flex flex-col items-center p-5 text-brand"
        >
          <Ticket size={48} />
          <div>New ticket</div>
        </Link>
        <Link
          to={`checklist/template`}
          className="rounded bg-white shadow-xl flex flex-col items-center p-5 text-brand"
        >
          <ListCheck size={48} />
          <div>New checklist</div>
        </Link>
        <Link
          to={`/tickets`}
          className="rounded bg-white shadow-xl flex flex-col items-center p-5 text-brand"
        >
          <Ticket size={48} />
          <div>My tickets</div>
        </Link>
        <Link
          to={`/checklists`}
          className="rounded bg-white shadow-xl flex flex-col items-center p-5 text-brand"
        >
          <ListCheck size={48} />
          <div>My checklists</div>
        </Link>
      </SimpleGrid>
    </div>
  );
}

export default Start;
