import { LoadingOverlay, Title, Text } from '@mantine/core';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import GetChecklistTemplate from 'Mobile/api/Checklist/template';
import { showErrorToast } from 'Shared/helpers/ui';
import { ChevronRight, X } from 'tabler-icons-react';
import { ChecklistTemplate } from 'types/Checklist';

export default function Template() {
  const { isLoading, data } = useQuery<ChecklistTemplate[], Error>(
    'checklistTemplates',
    GetChecklistTemplate,
    {
      onError: () => {
        showErrorToast('Fetching of templates failed');
      },
    }
  );

  return (
    <section>
      <LoadingOverlay visible={isLoading} />
      <div className="flex justify-between items-center">
        <Title>Select Template</Title>
        <Link to="/start" className="text-brand inline-flex items-center">
          Cancel <X />
        </Link>
      </div>
      <Text className="mb-10">
        Select the template to create checklist from
      </Text>
      {data?.map((template) => (
        <Link
          to={`/start/checklist/${template.CheckListTemplateId.toString()}/checklist`}
          className="bg-white p-4 rounded-lg mt-3 flex text-2xl font-bold justify-between items-center"
          key={template.CheckListTemplateId}
        >
          {template.TemplateName}
          <ChevronRight className="text-brand" />
        </Link>
      ))}
    </section>
  );
}
