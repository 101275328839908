import { Route, Routes } from 'react-router-dom';
import Attach from './attach';
import TicketCreate from './create';
import TicketLabels from './label';

export default function Index() {
  return (
    <Routes>
      <Route path="attach" element={<Attach />} />
      <Route index path="/" element={<TicketCreate />} />
      <Route index path="label/:id" element={<TicketLabels />} />
    </Routes>
  );
}
