export enum FormTicketMode {
  AddTicket = 'AddTicket',
  EditTicket = 'EditTicket',
  DeleteTicket = 'DeleteTicket',
  AddTicketComment = 'AddTicketComment',
}

export enum FormTimeLineMode {
  AddTimeLine = 'AddTimeLine',
  EditTimeLine = 'EditTimeLine',
  DeleteTimeLine = 'DeleteTimeLine',
}

export interface TimeLine {
  FDSTicketId: number;
  EntityName: string;
  Action: string;
  PropertyName: string;
  ValueType: string;
  Title: string;
  Id: number;
  CreateDate: string;
  CreateUserId: string;
  NewValue: string;
}

export interface Ticket {
  Description?: string;
  DueDate?: string;
  FDSTicketId?: number;
  LocationId?: number;
  Status?: string;
  Title?: string;
  Labels?: string[];
  Attachments?: string[];
  TimeLines?: TimeLine[];
  CreateId?: string;
  Content?: string;
  CreateDate?: Date;
  TagIds?: string;
  CheckListId?: number;
  QuestionId?: number;
  UpdateDate?: Date;
  AssignUser?: string;
  LocationName?: string;
  AssignId?: string;
}

export interface TicketEditData {
  item?: Ticket;
  mode?: FormTicketMode | FormTimeLineMode;
}

export enum TicketStatus {
  Open = 'Open',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}
