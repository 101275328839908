import config from 'config';
import fetchWithAuth from 'Shared/helpers/fetch';
import { Ticket } from 'types/Ticket';

export default async function SendComment(ticketId: number, content: string) {
  return fetchWithAuth(`${config.apiBaseUrl}/Api/Data/FDSComment`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      FDSTicketId: ticketId,
      Comment: content,
    }),
  }).then((data) => data);
}
