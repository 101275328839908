import config from 'config';
import buildQuery from 'odata-query';
import fetchWithAuth from 'Shared/helpers/fetch';

const GetTicketReminders = async (id?: number) => {
  if (id) {
    const query = buildQuery({
      filter: {
        TicketId: id,
      },
      select: ['ReminderId', 'AssignUser', 'DueDate'],
    });
    return fetchWithAuth(`${config.apiBaseUrl}/Api/Reminders/odata${query}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((data) => data.value);
  }
};

export default GetTicketReminders;
