import config from 'config';
import fetchWithAuth from 'Shared/helpers/fetch';
import buildQuery from 'odata-query';

export async function GetChecklist(id: number) {
  const query = buildQuery({
    filter: [{ CheckListId: id }],
    select: ['Status', 'TemplateId', 'RecentlyTicketId'],
  });
  return fetchWithAuth(`${config.apiBaseUrl}/Api/Checklists/odata${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((data) => data.value[0]);
}
