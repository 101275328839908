import config from 'config';
import { UserLoginCredential, UserLoginFields } from 'types/Users';
import { Device } from '@capacitor/device';

export const refreshToken = async (
  token: string
): Promise<UserLoginCredential> => {
  return await fetch(`${config.apiBaseUrl}/Api/Token/RefreshAccessToken`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token }),
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        const err = await response.json();
        throw err;
      }
    })
    .then((data) => data);
};

export const mobileLogin = async (login: UserLoginFields) => {
  return fetch(`${config.apiBaseUrl}/Api/Mobile/Login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...login,
      KeepLogin: true,
      deviceId: (await Device.getId()).uuid,
    }),
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        const err = await response.json();
        throw err;
      }
    })
    .then((data) => data);
};
