import Upload from 'Mobile/components/UploadModal';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';
import { AttachFilesToTicket } from 'Mobile/api/Tickets/Create';
import { LoadingOverlay } from '@mantine/core';

interface AttachmentTabProps {
  id: number;
}

export default function AttachFiles({ id }: AttachmentTabProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const mutation = useMutation<any, Error, File[]>(
    'appendFiles',
    async (images) => {
      const result = [];
      for (const image of images) {
        result.push(
          AttachFilesToTicket(id, image).catch((err) => {
            showErrorToast(image.name + ' ' + err.Message);
          })
        );
      }
      await Promise.all(result);
    },
    {
      onSuccess: () => {
        showOkToast('Attached files successfully');
        queryClient.invalidateQueries(['ticketAttachment', id]);
        navigate(`/tickets/id/${id}/attachments`);
      },
      onError: () => {
        showErrorToast('Failed to attach files');
      },
    }
  );

  return (
    <>
      <LoadingOverlay visible={mutation.isLoading} />
      <Upload
        files={location.state?.files}
        onSave={(files) => {
          mutation.mutate(files);
        }}
        opened={true}
        onClose={() => {
          navigate(`/tickets/id/${id}/attachments`);
        }}
        clearAfterSave={false}
      />
    </>
  );
}
