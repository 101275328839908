import fetchWithAuth from 'Shared/helpers/fetch';
import config from 'config';

export const fetchLocations = async () => {
  return await fetchWithAuth(`${config.apiBaseUrl}/Api/Locations/odata`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((data) => data.value);
};
