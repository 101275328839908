import fetchWithAuth from 'Shared/helpers/fetch';
import config from 'config';

export default async function AttachFilesToChecklistQuestion(
  checklistId: number,
  file: File
) {
  const data = new FormData();
  data.append('', file);
  return fetchWithAuth(
    `${config.apiBaseUrl}/Api/Attachments/CheckListQuestion/${checklistId}`,
    {
      method: 'POST',
      body: data,
    }
  ).then((data) => data);
}
