import { Route, Routes } from 'react-router-dom';
import AttachFiles from './attach';
import Tickets from './attachTicket';
import Checklists from './checklist';
import Labels from './labels';
import Template from './template';

export default function Detail() {
  return (
    <Routes>
      <Route path="template" element={<Template />} />
      <Route path=":id/checklist" element={<Checklists />} />
      <Route path=":id/checklist/label" element={<Labels type="checklist" />} />
      <Route path=":id/ticket" element={<Tickets />} />
      <Route path=":id/ticket/attach" element={<AttachFiles />} />
      <Route path=":id/ticket/label" element={<Labels type="ticket" />} />
    </Routes>
  );
}
