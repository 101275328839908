import fetchWithAuth from 'Shared/helpers/fetch';
import { EnumCheckListAnswer } from 'types/Checklist';
import config from 'config';

export default async function AnswerChecklistQuestion(
  id: number,
  answer: EnumCheckListAnswer
): Promise<number> {
  return await fetchWithAuth(
    `${config.apiBaseUrl}/Api/Data/ChecklistQuestion/${id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Answer: answer,
      }),
    }
  ).then((data) => data);
}
