import { Textarea, Button, LoadingOverlay } from '@mantine/core';
import SendComment from 'Mobile/api/Tickets/Comment';
import Modal from 'Mobile/components/Modal';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';
import { Send } from 'tabler-icons-react';

interface CommentProps {
  id: number;
}

export default function Comment({ id }: CommentProps) {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<{ content: string }>();

  const queryClient = useQueryClient();
  const sendCommentMutation = useMutation<any, Error, string>(
    'sendComment',
    (content) => SendComment(id, content),
    {
      onSuccess: () => {
        showOkToast('Comment sent');
        queryClient.invalidateQueries(['ticketTimeline', id]);
        navigate(`/tickets/id/${id}/timeline`);
      },
      onError: () => {
        showErrorToast('Comment sending failed');
      },
    }
  );
  return (
    <Modal
      onClose={() => navigate(`/tickets/id/${id}/timeline`)}
      title="Comment"
    >
      <LoadingOverlay visible={sendCommentMutation.isLoading} />
      <form
        className="py-6 mt-3"
        onSubmit={handleSubmit((e) => sendCommentMutation.mutate(e.content))}
      >
        <Textarea
          placeholder="Your comment"
          required
          minRows={6}
          {...register('content')}
        />
        <Button
          className="bg-brand focus:bg-brand mt-5"
          size="lg"
          radius="md"
          fullWidth
          leftIcon={<Send />}
          type="submit"
        >
          Send
        </Button>
      </form>
    </Modal>
  );
}
