import { Route as AppRoute } from 'types/App';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import {
  BellRinging,
  Dashboard as DashboardIcon,
  LayoutGrid,
  ListCheck,
  Settings,
  Ticket,
} from 'tabler-icons-react';
import Start from 'Mobile/pages/Start';
import Login from 'Mobile/pages/Login';
import Checklists from 'Mobile/pages/Checklist/List';
import TicketLists from 'Mobile/pages/Tickets/List';
import TicketsCreate from 'Mobile/pages/Tickets/Create';
import ChecklistsCreate from 'Mobile/pages/Checklist/Create';
import MobileLayout from 'Mobile/components/MobileLayout';
import Detail from 'Mobile/pages/Tickets/Tabs';
import ChecklistDetail from 'Mobile/pages/Checklist/Tabs';
import AccountSettings from 'Mobile/pages/Settings';
import AttachmentTab from 'Mobile/pages/Checklist/Tabs/attachmentTab';
import { App as CapacitorApp } from '@capacitor/app';
import { useEffect, useRef } from 'react';
import Reminders from './pages/Reminders';
import ScrollToTop from './components/ScrollToTop';

export const routes: AppRoute[] = [
  {
    path: 'login',
    component: <Login />,
    menuItem: { parent: '', icon: DashboardIcon, label: '' },
  },
  {
    path: 'start',
    component: <Start />,
    menuItem: { parent: '', icon: LayoutGrid, label: 'Start' },
  },
  {
    path: 'tickets/*',
    component: <TicketLists />,
    menuItem: { parent: '', icon: Ticket, label: 'Tickets' },
  },
  {
    path: 'start/ticket/*',
    component: <TicketsCreate />,
    menuItem: { parent: '/start', icon: Ticket, label: 'Tickets' },
  },
  {
    path: 'tickets/id/:id/*',
    component: <Detail />,
    menuItem: { parent: '/tickets', icon: Ticket, label: 'Tickets' },
  },
  {
    path: 'checklists/*',
    component: <Checklists />,
    menuItem: { parent: '', icon: ListCheck, label: 'Checklists' },
  },
  {
    path: 'checklists/id/:id/*',
    component: <ChecklistDetail />,
    menuItem: { parent: '/checklist', icon: ListCheck, label: 'Checklists' },
  },
  {
    path: 'checklists/id/:id/section/:sectionId/attachment/:questionId',
    component: <AttachmentTab />,
    menuItem: { parent: '/checklist', icon: ListCheck, label: 'Checklists' },
  },
  {
    path: 'start/checklist/*',
    component: <ChecklistsCreate />,
    menuItem: { parent: '/start', icon: ListCheck, label: 'Checklist' },
  },
  // {
  //   path: "checklists/details/:checklistId",
  //   component: <ChecklistsDetails />,
  //   menuItem: {
  //     parent: "checklists",
  //     icon: ListCheck,
  //     label: "View Checklist",
  //   },
  // },
  {
    path: 'reminders/*',
    component: <Reminders />,
    menuItem: { parent: '', icon: BellRinging, label: 'Reminders' },
  },
  {
    path: 'settings/*',
    component: <AccountSettings />,
    menuItem: { parent: '', icon: Settings, label: 'Settings' },
  },
];

function MobileRoutes() {
  const location = useLocation();
  const navigate = useNavigate();
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      CapacitorApp.addListener('backButton', ({ canGoBack }) => {
        if (!canGoBack) {
          CapacitorApp.exitApp();
        } else {
          navigate(-1);
        }
      });
      isFirstRender.current = false;
    }
    () => {
      CapacitorApp.removeAllListeners();
    };
  }, []);
  const routeComponents = [
    <Route
      path=""
      key={'redirect'}
      element={<Navigate to="start" replace />}
    />,
    ...routes
      .filter((route: AppRoute) => Boolean(route.path))
      .map((route: AppRoute) => (
        <Route key={route.path} path={route.path} element={route.component} />
      )),
    <Route
      path="*"
      key={'fallback'}
      element={<Navigate to="start" replace />}
    />,
  ];

  return (
    <>
      <ScrollToTop />
      <Routes location={location}>
        <Route path={'/'} element={<MobileLayout />}>
          {routeComponents}
        </Route>
      </Routes>
    </>
  );
}

export default MobileRoutes;
