import { Title } from '@mantine/core';
import { Link, NavLink, Route, Routes, useParams } from 'react-router-dom';
import { X } from 'tabler-icons-react';
import DetailTab from './detailTab';
import TimelineTab from './timelineTab';
import AttachmentTab from './attachmentTab';
import { ReactNode } from 'react';
import Reminder from './reminder';
import AttachFiles from './attachfiles';
import Comment from './comment';

const Wrapper = ({ children, id }: { children: ReactNode; id?: string }) => {
  return (
    <div className="pt-36">
      <div className="fixed bg-gray-100 z-10 top-0 pt-6 pb-2 left-0 right-0">
        <div className="flex justify-between items-center px-8">
          <Title>Tickets</Title>
          <Link to="/tickets" className="text-brand inline-flex items-center">
            Close <X />
          </Link>
        </div>
        <div className="flex gap-x-4 mt-4 py-4 border-y-2  px-8">
          <NavLink
            to={`/tickets/id/${id}/`}
            end={true}
            className={({ isActive }) =>
              isActive
                ? 'font-bold text-xl border-b-2 block border-brand px-2'
                : 'font-bold text-xl text-brand'
            }
          >
            Details
          </NavLink>
          <NavLink
            to={`/tickets/id/${id}/attachments`}
            className={({ isActive }) =>
              isActive
                ? 'font-bold text-xl border-b-2 block border-brand px-2'
                : 'font-bold text-xl text-brand'
            }
          >
            Attachments
          </NavLink>
          <NavLink
            to={`/tickets/id/${id}/timeline`}
            className={({ isActive }) =>
              isActive
                ? 'font-bold text-xl border-b-2 block border-brand px-2'
                : 'font-bold text-xl text-brand'
            }
          >
            Timeline
          </NavLink>
        </div>
      </div>
      {children}
    </div>
  );
};
export default function Detail() {
  const { id } = useParams();

  return (
    <Routes>
      <Route
        path="reminder/:reminderId"
        element={<Reminder id={parseInt(id ?? '')} />}
      />
      <Route
        path="/"
        element={
          <Wrapper id={id}>
            <DetailTab id={parseInt(id ?? '')} />
          </Wrapper>
        }
      />
      <Route
        path="attachments"
        element={
          <Wrapper id={id}>
            <AttachmentTab id={parseInt(id ?? '')} />
          </Wrapper>
        }
      />
      <Route
        path="attachments/new"
        element={<AttachFiles id={parseInt(id ?? '')} />}
      />
      <Route
        path="timeline"
        element={
          <Wrapper id={id}>
            <TimelineTab id={parseInt(id ?? '')} />
          </Wrapper>
        }
      />
      <Route path="comment" element={<Comment id={parseInt(id ?? '')} />} />
    </Routes>
  );
}
