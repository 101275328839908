import config from 'config';
import buildQuery from 'odata-query';
import fetchWithAuth from 'Shared/helpers/fetch';
import { TicketFilterDTO } from 'types/Ticket';

export async function Tickets(
  filters: Partial<TicketFilterDTO> | undefined,
  perPage: number,
  currentPage: number,
  search: string | undefined,
  sort: {
    type: string;
    dir: string;
  }
) {
  const filter: any[] = [];

  if (filters) {
    if (filters.Status) {
      filter.push({ Status: filters?.Status });
    }
    if (filters.Locations && filters.Locations.length > 0) {
      filter.push({
        LocationId: {
          in: filters?.Locations?.map((x) => parseInt(x)),
        },
      });
    }
    if (filters.Labels && filters.Labels.length > 0) {
      filter.push({
        Labels: {
          any: {
            '': {
              in: filters?.Labels,
            },
          },
        },
      });
    }
    if (filters.Reporter) {
      filter.push({
        CreateId: filters?.Reporter,
      });
    }
    if (filters.Assignee) {
      filter.push({
        AssignId: filters?.Assignee,
      });
    }
    if (filters.CreateDate) {
      filter.push({
        CreateDate: {
          ...(filters.CreateDate[0] && { ge: filters?.CreateDate[0] }),
          ...(filters.CreateDate[1] && { le: filters?.CreateDate[1] }),
        },
      });
    }
    if (filters.DueDate) {
      filter.push({
        DueDate: {
          ...(filters.DueDate[0] && { ge: filters?.DueDate[0] }),
          ...(filters.DueDate[1] && { le: filters?.DueDate[1] }),
        },
      });
    }
  }
  if (search) {
    filter.push({
      or: {
        [`indexof(Description, '${search}')`]: {
          gt: -1,
        },
        [`indexof(Title, '${search}')`]: {
          gt: -1,
        },
      },
    });
  }

  const query = buildQuery({
    filter,
    select: [
      'FDSTicketId',
      'Status',
      'Title',
      'Description',
      'CreateName',
      'AssignName',
      'CreateDate',
      'DueDate',
    ],
    top: perPage,
    skip: perPage * (currentPage - 1),
    count: true,
    orderBy: [`${sort.type} ${sort.dir}`],
  });
  return fetchWithAuth(`${config.apiBaseUrl}/Api/Tickets/odata${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((data) => ({
    count: data['@odata.count'],
    value: data.value,
  }));
}
