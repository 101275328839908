import { ActionIcon, Button, LoadingOverlay, MultiSelect } from '@mantine/core';
import addTag from 'Mobile/api/Tags/create';
import fetchTags from 'Mobile/api/Tags/list';
import Modal from 'Mobile/components/Modal';
import { useForm, Controller } from 'react-hook-form';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';
import { CircleMinus } from 'tabler-icons-react';
import { Tags } from 'types/Tags';

export default function ChecklistLabels({
  type,
}: {
  type: 'ticket' | 'checklist';
}) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const location = useLocation();
  const { control, handleSubmit } = useForm<{
    Tags: string[];
  }>({
    defaultValues: {
      Tags: location.state.Tags,
    },
  });
  const navigate = useNavigate();
  const addTagMutation = useMutation(addTag, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['labels'], (prev: any) => [
        {
          Id: data,
          TagName: variables,
        },
        ...prev,
      ]);
      showOkToast('Label successfully created');
    },
    onError: () => {
      showErrorToast('Error Creating New Label');
    },
  });
  const tags = useQuery<Tags[], Error>('labels', fetchTags, {
    onError: () => {
      showErrorToast('Fetching of labels failed');
    },
  });
  return (
    <>
      <LoadingOverlay visible={addTagMutation.isLoading || tags.isLoading} />
      <Modal
        onClose={() =>
          navigate(`/start/checklist/${id}/${type}`, {
            state: location.state,
          })
        }
        title="Labels"
        description={`Create labels for this ${type}`}
      >
        <form
          onSubmit={handleSubmit((e) =>
            navigate(`/start/checklist/${id}/${type}`, {
              state: {
                ...location.state,
                Tags: e.Tags,
              },
            })
          )}
        >
          <Controller
            control={control}
            name="Tags"
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <MultiSelect
                  data={
                    tags.data?.map((tag) => ({
                      value: tag.TagName,
                      label: tag.TagName,
                    })) ?? []
                  }
                  value={value}
                  placeholder="Select tags"
                  onChange={onChange}
                  getCreateLabel={(query) => `+ Create ${query}`}
                  onCreate={addTagMutation.mutate}
                  onBlur={onBlur}
                  searchable
                  creatable
                />
                <div className="flex flex-wrap gap-3">
                  {value?.map((label) => (
                    <div key={label} className="flex bg-white p-2">
                      {label}
                      <ActionIcon
                        onClick={() =>
                          onChange(value.filter((x) => x !== label))
                        }
                      >
                        <CircleMinus size={16} className="text-brand" />
                      </ActionIcon>
                    </div>
                  ))}
                </div>
              </>
            )}
          />

          <Button
            fullWidth
            color="black"
            className="bg-brand mt-10 active:bg-brand"
            type="submit"
          >
            Continue
          </Button>
        </form>
      </Modal>
    </>
  );
}
