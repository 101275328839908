import config from 'config';
import fetchWithAuth from 'Shared/helpers/fetch';
import { User } from 'types/Users';

export default async function updateUser(
  id: string,
  ticket: User
): Promise<number> {
  return fetchWithAuth(
    `${config.apiBaseUrl}/Api/Users/Detail/${id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...ticket }),
    },
    'text'
  ).then((data) => data);
}
