import { Badge, Title } from '@mantine/core';
import { useQuery } from 'react-query';
import {
  Link,
  NavLink,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';
import { GetChecklist } from 'Mobile/api/Checklist/get';
import { useStore } from 'Shared/data/Store';
import { showErrorToast } from 'Shared/helpers/ui';
import { ListCheck, X } from 'tabler-icons-react';
import { Checklist, ChecklistStatus } from 'types/Checklist';
import SectionTab from './sectionTab';
import TicketTab from './ticketTab';

const color = {
  [ChecklistStatus.Draft]: 'lime',
  [ChecklistStatus.Submit]: 'green',
  [ChecklistStatus.Disable]: 'gray',
};
export default function Detail() {
  const { id } = useParams();
  const { pathname } = useLocation();

  const { queryStaleTimeMs } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
  }));

  const { data } = useQuery<Checklist, Error>(
    ['checklist', id],
    () => GetChecklist(parseInt(id ?? '')),
    {
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of Checklist failed');
      },
    }
  );
  return (
    <div className="pt-44">
      <div className="fixed bg-gray-100 z-10 top-0 pt-6 pb-2 left-0 right-0">
        <div className="flex justify-between items-center px-8">
          <Title>Checklist</Title>
          <Link
            to="/checklists"
            className="text-brand inline-flex items-center"
          >
            Close <X />
          </Link>
        </div>
        <div className="mt-3 flex px-8">
          <ListCheck className="border" />
          <span className="font-bold ml-2">Checklist number {id}</span>
          {data?.Status && (
            <Badge
              variant="filled"
              color={color[data.Status]}
              className="ml-auto"
            >
              {data.Status}
            </Badge>
          )}
        </div>
        <div className="flex gap-x-4 mt-4 py-4 border-y-2  px-8">
          <NavLink
            to=""
            end={true}
            className={() =>
              !pathname.toLowerCase().includes('ticket')
                ? 'font-bold text-xl border-b-2 block border-brand px-2'
                : 'font-bold text-xl text-brand'
            }
          >
            Sections
          </NavLink>
          <NavLink
            to="Ticket"
            className={({ isActive }) =>
              isActive
                ? 'font-bold text-xl border-b-2 block border-brand px-2'
                : 'font-bold text-xl text-brand'
            }
          >
            Tickets
          </NavLink>
        </div>
      </div>
      <Routes>
        <Route path="/" element={<SectionTab id={parseInt(id ?? '')} />} />
        <Route
          path="section/:sectionId"
          element={<SectionTab id={parseInt(id ?? '')} />}
        />
        <Route path="ticket" element={<TicketTab id={parseInt(id ?? '')} />} />
      </Routes>
    </div>
  );
}
