import { ActionIcon, Affix, LoadingOverlay, Text } from '@mantine/core';
import { useQuery } from 'react-query';
import { useStore } from 'Shared/data/Store';
import GetTicketAttachments, {
  getFileUrl,
} from 'Mobile/api/Tickets/Attachment';
import { showErrorToast } from 'Shared/helpers/ui';
import { FileText, Photo, Plus } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
interface AttachmentTabProps {
  id: number;
}
interface FileType {
  key: string;
  value: string;
  name: string;
}
export default function AttachmentTab({ id }: AttachmentTabProps) {
  const { queryStaleTimeMs } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
  }));

  const isImage = (fileName: string) => {
    if (
      fileName.endsWith('.jpg') ||
      fileName.endsWith('.jpeg') ||
      fileName.endsWith('.png') ||
      fileName.endsWith('.gif')
    ) {
      return true;
    }
    return false;
  };

  const { isLoading, data } = useQuery<
    {
      images: FileType[];
      files: FileType[];
    },
    Error
  >(
    ['ticketAttachment', id],
    () =>
      GetTicketAttachments(id, 'Ticket').then(async (files) => {
        const attachments = [];
        for (const file of files) {
          attachments.push(getFileUrl(file.FileUUID));
        }
        const attachments_name = await Promise.all(attachments).then((result) =>
          result.map((x, i) => ({
            value: x,
            key: files[i].FileUUID,
            name: files[i].FileName,
          }))
        );
        return {
          images: attachments_name.filter((x) => isImage(x.key)),
          files: attachments_name.filter((x) => !isImage(x.key)),
        };
      }),
    {
      staleTime: queryStaleTimeMs,
      enabled: !!id,
      onError: () => {
        showErrorToast('Fetching of attachments failed');
      },
    }
  );

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <Text color="dimmed" className="flex mt-3 gap-x-2">
        <FileText />
        Documents
      </Text>
      {data?.files.map((x) => (
        <a
          href={x.value}
          key={x.key}
          className="bg-white p-3 mt-2 break-all rounded text-start inline-block w-full"
          download
        >
          {x.name}
        </a>
      ))}
      <Text color="dimmed" className="flex mt-7 gap-x-2">
        <Photo />
        Photos
      </Text>
      <div className="bg-white p-3 rounded grid grid-cols-2 gap-3">
        {data?.images.map((x) => (
          <a href={x.value} download key={x.key}>
            <img src={x.value} />
          </a>
        ))}
      </div>
      <Affix position={{ bottom: 130, right: 35 }}>
        <ActionIcon
          component={Link}
          className="bg-brand rounded-full text-white text-center"
          size={60}
          to="new"
        >
          <Plus size={40} />
        </ActionIcon>
      </Affix>
    </>
  );
}
