import React from 'react';
import { Icon } from 'tabler-icons-react';
import { NavLink } from 'react-router-dom';

interface NavbarTabProps {
  icon: Icon;
  label: string;
  path: string;
  className?: string;
}

export default function NavbarTab({
  icon: Icon,
  label,
  path,
  className,
}: NavbarTabProps) {
  const linkClass = `h-full select-none flex flex-col justify-center items-center ${className} gap-1`;

  return (
    <NavLink
      className={({ isActive }) =>
        linkClass + (isActive ? ' bg-brand text-white' : ' hover:bg-slate-100')
      }
      role="button"
      to={path.endsWith('/*') ? path.slice(0, -2) : path}
    >
      <Icon size={32} />
      <span className="font-medium text-sm">{label}</span>
    </NavLink>
  );
}
