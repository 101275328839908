import config from 'config';
import buildQuery from 'odata-query';
import fetchWithAuth from 'Shared/helpers/fetch';

export async function GetTicketTimeLines(id: number) {
  const query = buildQuery({
    filter: [{ FDSTicketId: id }],
    select: ['TimeLines'],
  });
  return fetchWithAuth(`${config.apiBaseUrl}/Api/Tickets/odata${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((data) => data.value[0]);
}
