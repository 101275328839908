import config from 'config';
import fetchWithAuth from 'Shared/helpers/fetch';
import { Ticket, TicketStatus } from 'types/Ticket';

export default async function CancelTicket(id: number): Promise<number> {
  return fetchWithAuth(`${config.apiBaseUrl}/Api/Data/FDSTicket/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ Status: TicketStatus.Cancelled }),
  }).then((data) => data);
}
