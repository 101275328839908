import {
  Badge,
  LoadingOverlay,
  Progress,
  SegmentedControl,
  Title,
  Text,
  Button,
  TextInput,
  List,
  ActionIcon,
  Loader,
} from '@mantine/core';
import { formatDistance } from 'date-fns';
import Pagination from 'Mobile/components/Pagination';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import ListChecklist from 'Mobile/api/Checklist/list';
import { useStore } from 'Shared/data/Store';
import { showErrorToast } from 'Shared/helpers/ui';
import {
  ArrowsSort,
  Filter,
  ListCheck,
  MapPin,
  PlayerPlay,
  Search,
  X,
} from 'tabler-icons-react';
import {
  Checklist,
  ChecklistFilterDTO,
  ChecklistStatus,
} from 'types/Checklist';
import { User } from 'types/Users';
import { css } from '@emotion/react';
import { tailwindConfig } from 'Shared/helpers/styles';
import { useDebouncedValue } from '@mantine/hooks';
const color = {
  [ChecklistStatus.Draft]: 'lime',
  [ChecklistStatus.Submit]: 'green',
  [ChecklistStatus.Disable]: 'gray',
};
const sortstyle = css`
  .mantine-SegmentedControl-label {
    color: ${(tailwindConfig.theme?.textColor as any)?.brand};
  }
  ,
  .mantine-SegmentedControl-labelActive {
    color: white;
    background: ${(tailwindConfig.theme?.backgroundColor as any)?.brand};
  }
`;
const segmentStyle = css`
  label {
    color: ${(tailwindConfig.theme?.textColor as any)?.brand};
    &.mantine-SegmentedControl-labelActive {
      font-weight: bold;
    }
  }
`;
export default function Checklists() {
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const location = useLocation();
  const [filter, setFilter] = useState<Partial<ChecklistFilterDTO>>(
    location.state
  );
  const [activeInput, setActiveInput] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [sort, setSort] = useState({
    type: 'CreateDate',
    dir: '',
  });
  const [searchTemp, setSearchTemp] = useState('');
  const [debouncedSearch] = useDebouncedValue(searchTemp, 300);

  const { queryStaleTimeMs } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
  }));
  const { isLoading, data } = useQuery<
    { count: number; value: Checklist[] },
    Error
  >(
    ['checklist', currentPage, perPage, filter, sort, search],
    () => ListChecklist(currentPage, perPage, filter, search, sort),
    {
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of tickets failed');
      },
    }
  );
  const searchChecklist = useQuery<
    { count: number; value: Checklist[] },
    Error
  >(
    ['searchChecklist', debouncedSearch],
    () =>
      ListChecklist(1, 5, filter, debouncedSearch, {
        type: 'CreateDate',
        dir: 'desc',
      }),
    {
      onError: () => {
        showErrorToast('Search failed');
      },
      enabled: Boolean(debouncedSearch),
    }
  );

  return (
    <section className="pt-24">
      <LoadingOverlay visible={isLoading} />
      <div className="fixed bg-gray-100 z-10 top-0 pt-6 pb-2 left-0 right-0 px-8">
        <div className="flex justify-between items-center">
          <Title>Checklists</Title>
          <SegmentedControl
            data={[
              { label: 'Started', value: '0' },
              { label: 'Completed', value: '1' },
            ]}
            css={segmentStyle}
            radius="xl"
            onChange={(val) => {
              setCurrentPage(1);
              if (val === '1') {
                setFilter({
                  ...filter,
                  PercentageAnswered: 1,
                });
              } else {
                setFilter({
                  ...filter,
                  PercentageAnswered: undefined,
                });
              }
            }}
          />
        </div>
        <div className="flex gap-x-2 mt-3">
          <TextInput
            icon={<Search className="text-black" />}
            placeholder="Search checklists"
            className="grow relative"
            type="search"
            onChange={(e) => {
              setSearchTemp(e.currentTarget.value);
            }}
            onFocus={() => {
              setActiveInput(true);
            }}
            rightSection={
              activeInput && (
                <Button
                  className="text-gray-400"
                  onClick={() => {
                    setActiveInput(false);
                  }}
                >
                  <X />
                </Button>
              )
            }
          />
          {activeInput && (
            <div className="absolute bg-white flex flex-col mt-10 left-2 right-2 text-gray-500 rounded-lg">
              {searchChecklist.isLoading ? (
                <Loader className="mx-auto my-5" />
              ) : (
                <List className="w-full">
                  {(!searchChecklist.data ||
                    searchChecklist.data.count === 0) && (
                    <div className="my-5 text-center">No data found</div>
                  )}
                  {searchChecklist.data?.value.map((x) => (
                    <List.Item
                      key={x.CheckListId}
                      className="pt-3 pb-3 border-b-2 w-full px-10"
                    >
                      <Link to={'id/' + x.CheckListId.toString()}>
                        <div className="flex mb-2 gap-x-4">
                          <MapPin /> {x.LocationName}
                        </div>
                        <div className="flex gap-x-4">
                          <ListCheck /> {x.CheckListName}
                        </div>
                      </Link>
                    </List.Item>
                  ))}
                </List>
              )}

              <Button
                className="text-brand text-xl mx-auto my-5 active:bg-white"
                fullWidth
                onClick={() => {
                  setSearch(searchTemp);
                  setActiveInput(false);
                }}
              >
                View All
              </Button>
            </div>
          )}
          {!activeInput && (
            <>
              <ActionIcon
                component={Link}
                to="filter"
                radius="xl"
                state={filter}
                variant="outline"
                className="border-brand"
              >
                <Filter className="text-brand" size={20} />
              </ActionIcon>
              <Button
                className="text-black"
                compact
                variant="subtle"
                onClick={() => setOpenSort(!openSort)}
              >
                Sort By
                <ArrowsSort className="text-brand ml-2" />
              </Button>
            </>
          )}
        </div>
      </div>
      {openSort && (
        <div className="bg-white pt-3 mt-5 rounded-lg">
          <div className="flex justify-evenly">
            <SegmentedControl
              data={[
                { label: 'Created Date', value: 'CreateDate' },
                { label: 'Updated Date', value: 'UpdateDate' },
                { label: 'Due Date', value: 'DueDate' },
              ]}
              radius="xl"
              css={sortstyle}
              className="bg-transparent"
              onChange={(val) =>
                setSort({
                  ...sort,
                  type: val,
                })
              }
            />
          </div>
          <div className="flex border-t-2 mt-3 justify-evenly">
            <label className="border-r-2 flex-grow text-center py-3">
              <input
                type="radio"
                value=""
                className="mr-2"
                checked={sort.dir === ''}
                onChange={(e) =>
                  setSort({
                    ...sort,
                    dir: e.target.value,
                  })
                }
              />
              Ascending
            </label>
            <label className="flex-grow text-center py-3">
              <input
                type="radio"
                value="desc"
                className="mr-2"
                checked={sort.dir === 'desc'}
                onChange={(e) =>
                  setSort({
                    ...sort,
                    dir: e.target.value,
                  })
                }
              />
              Descending
            </label>
          </div>
        </div>
      )}
      {data?.value.map((checklist) => (
        <Link
          to={`id/${checklist.CheckListId.toString()}`}
          className="bg-white p-4 rounded-lg mt-3 block"
          key={checklist.CheckListId}
        >
          <div className="flex gap-x-1 text-gray-500 items-center">
            <PlayerPlay />
            Started
            <Badge variant="filled" color={'gray'} className="ml-auto">
              {formatDistance(
                checklist.CreateDate
                  ? new Date(checklist.CreateDate)
                  : new Date(),
                new Date(),
                { addSuffix: true }
              )}
            </Badge>
            <Badge
              variant="filled"
              color={color[checklist.Status]}
              className="ml-3"
            >
              {checklist.Status}
            </Badge>
          </div>
          <h4 className="font-bold text-lg">{checklist.CheckListName}</h4>
          <Progress
            color={color[checklist.Status]}
            value={checklist.PercentageAnswered * 100}
            className="my-3"
          />
          <div className="flex items-center text-gray-500 gap-x-2">
            <Text
              color={color[checklist.Status]}
              weight="bolder"
              className="text-3xl"
            >
              {+(checklist.PercentageAnswered * 100).toFixed(2)}%
            </Text>
            <span>FINISHED</span>
          </div>
        </Link>
      ))}
      <Pagination
        perPage={perPage}
        currentPage={currentPage}
        onChange={(perPage, currentPage) => {
          setPerPage(perPage);
          setCurrentPage(currentPage);
        }}
        totalPages={Math.ceil((data?.count ?? 0) / perPage)}
      />
    </section>
  );
}
