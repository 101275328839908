import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AppType } from 'types/App';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
// import '@bugsnag/core';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import config from 'config';

function start(appType: AppType, appRoutes: ReactElement) {
  Bugsnag.start({
    apiKey: config.bugsnagApiKey,
    plugins: [new BugsnagPluginReact()],
  });

  const bugsnagReact = Bugsnag.getPlugin('react');

  if (!bugsnagReact) {
    throw new Error('Make sure @bugsnag/plugin-react is properly configured');
  }

  const ErrorBoundary = bugsnagReact.createErrorBoundary(React);

  const queryClient = new QueryClient();

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <App type={appType} appRoutes={appRoutes} />
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={true} />
        </QueryClientProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

export default start;
