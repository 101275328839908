import config from 'config';
import buildQuery from 'odata-query';
import fetchWithAuth from 'Shared/helpers/fetch';

export async function GetUser(id: string) {
  const query = buildQuery({
    filter: [{ Id: id }],
    select: ['FirstName', 'Email', 'Surname'],
  });
  return fetchWithAuth(`${config.apiBaseUrl}/Api/Users/-1${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((data) => data.value[0]);
}
