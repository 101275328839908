export enum ChecklistStatus {
  Draft = 'Draft',
  Submit = 'Submit',
  Disable = 'Disable',
}

export enum FormChecklistMode {
  Add = 'Add',
  Edit = 'Edit',
  Delete = 'Delete',
  AddLabel = 'AddLabel',
}

export enum ChecklistResult {
  Passed = 'Passed',
  Failed = 'Failed',
  NotApplicable = 'NotApplicable',
}

export interface Checklist {
  CheckListId: string;
  id: string;
  Status: ChecklistStatus;
  title: string;
  labels: string[];
  TotalAnswered: number;
  TotalPassed: number;
  TotalQuestion: number;
  PercentagePassed: number;
  passedQuestions: number;
  CreateDate: string;
  lastUpdated: string;
  TemplateId: string;
  LocationId: number;
  LocationName: string;
  CheckListName: string;
  PercentageAnswered: number;
  Sections: CheckListSectionDTO[];
  RecentlyTicketId: number;
  Content: string;
}

export interface CheckListSectionDTO {
  CheckListSectionId: number;
  Order: number;
  SectionName: string;
  Questions: CheckListQuestionDTO[];
}

export interface CheckListQuestionDTO {
  CheckListQuestionId: number;
  Order: number;
  Question: string;
  Answer: EnumCheckListAnswer;
  Attachments: string[];
}

export enum EnumCheckListAnswer {
  Passed = 'Passed',
  Failed = 'Failed',
  NotApplicable = 'NotApplicable',
}
// export interface ChecklistTemplateQuestion {
//   id: string;
//   question: string;
// }

// export interface ChecklistTemplateSection {
//   id: string;
//   name: string;
//   questions: ChecklistTemplateQuestion[];
// }

export interface ChecklistTemplate {
  CheckListTemplateId: string;
  TemplateName: string;
}

export interface ChecklistAnswer {
  question: string;
  answer: ChecklistResult;
}

export interface ChecklistSectionQuestions {
  CheckListQuestionId: number;
  Order: number;
  Question: string;
  Answer: string | null;
  Attachments: string[];
}

export interface ChecklistQuestionAnswer {
  questionId?: number;
  answer?: string | null;
}

export interface ChecklistSection {
  CheckListSectionId: number;
  SectionName: string;
  answers: ChecklistAnswer[];
  Questions: ChecklistSectionQuestions[];
}

export interface ChecklistDetails extends Checklist {
  sections: ChecklistSection[];
}

export interface ChecklistFilters {
  labels: string[];
  templateId: string;
  status: string;
  locationId: string;
  dateRage: [Date | null, Date | null];
}

export interface ChecklistFilterDTO {
  Locations: string[];
  Labels: string[];
  Status: string;
  CreateDate: [Date | null, Date | null];
  DueDate: [Date | null, Date | null];
  Reporter: string;
  Assignee: string;
  PercentageAnswered: number;
}
