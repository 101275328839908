import {
  ActionIcon,
  Badge,
  Button,
  LoadingOverlay,
  Select,
  Textarea,
  TextInput,
} from '@mantine/core';
import { formatDistance } from 'date-fns';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetchAreas } from 'Mobile/api/Locations/area';
import { fetchLocations } from 'Mobile/api/Locations/location';
import { fetchFloors } from 'Mobile/api/Locations/floor';
import { useStore } from 'Shared/data/Store';
import { GetTicket } from 'Mobile/api/Tickets/Get';
import fetchAllUsers from 'Mobile/api/Users/list';
import {
  Bucket,
  Mail,
  Pencil,
  Phone,
  X,
  CircleMinus,
  CirclePlus,
  Bell,
  Calendar,
} from 'tabler-icons-react';
import { Ticket, TicketReminderDTO, TicketStatus } from 'types/Ticket';
import { User } from 'types/Users';
import { Area, Floor, Location } from 'types/Locations';
import PutTicket from 'Mobile/api/Tickets/Put';
import CancelTicket from 'Mobile/api/Tickets/Cancel';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';
import { fetchTicketTags } from 'Mobile/api/Tags/ticketTags';
import { removeEntityTagSingle } from 'Mobile/api/Tags/unattach';
import { Link, useNavigate } from 'react-router-dom';
import { EditReminder } from 'Mobile/api/Reminders/EditReminder';
import GetTicketReminders from 'Mobile/api/Reminders/GetTicketReminders';
import { EntityTags } from 'types/Tags';

interface DetailProps {
  id: number;
}

interface SelectData {
  value: string;
  label: string;
}

export default function DetailTab({ id }: DetailProps) {
  const [editDetail, setEditDetail] = useState(false);
  const { queryStaleTimeMs, isAdmin } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
    isAdmin: state.user.isAdmin,
  }));
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isLoading, data, refetch } = useQuery<Ticket, Error>(
    ['ticket', id],
    () => GetTicket({ FDSTicketId: id }),
    {
      staleTime: queryStaleTimeMs,
      onSuccess: (data) => {
        setCanEdit(localStorage.getItem('userId') === data.CreateId || isAdmin);
        reset(data);
      },
      onError: () => {
        showErrorToast('Fetching of Ticket failed');
      },
    }
  );

  const ticketTags = useQuery<EntityTags[], Error>(
    ['ticketLabels', id],
    () => fetchTicketTags(id),
    {
      onError: () => {
        showErrorToast('Fetching of labels failed');
      },
    }
  );

  const remindersQuery = useQuery<TicketReminderDTO[], Error>(
    ['ticketReminders', id],
    () => GetTicketReminders(id),
    {
      staleTime: queryStaleTimeMs,
      enabled: !!id,
      onError: () => {
        showErrorToast('Fetching of Ticket Reminders failed');
      },
    }
  );

  const { register, handleSubmit, control, watch, reset } = useForm<Ticket>({
    defaultValues: data,
  });

  const { LocationId, FloorId } = watch();

  const [canEdit, setCanEdit] = useState(
    isAdmin || (data && data.CreateId === localStorage.getItem('userId'))
  );

  const usersQuery = useQuery<User[], Error, SelectData[]>(
    'users',
    fetchAllUsers,
    {
      select: (users) =>
        [{ value: '0', label: 'All' }].concat(
          users.map((user) => ({
            value: user.Id ?? '',
            label: user.FirstName + ' ' + user.Surname,
          })) ?? []
        ),
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of users failed');
      },
    }
  );

  const floorsQuery = useQuery<Floor[], Error, SelectData[]>(
    ['floors', LocationId],
    () => fetchFloors(LocationId),
    {
      select: (floors) =>
        floors.map(
          (floor) =>
            ({
              value: floor.FloorId?.toString() ?? '',
              label: floor.Name ?? '',
            } ?? [])
        ),
      enabled: !!LocationId,
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of floors failed');
      },
    }
  );

  const areasQuery = useQuery<Area[], Error, SelectData[]>(
    ['areas', LocationId, FloorId],
    () => fetchAreas(LocationId, FloorId),
    {
      select: (areas) =>
        areas.map(
          (area) =>
            ({
              value: area.AreaId?.toString() ?? '',
              label: area.Name ?? '',
            } ?? [])
        ),
      enabled: !!LocationId,
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of areas failed');
      },
    }
  );

  const locationsQuery = useQuery<Location[], Error, SelectData[]>(
    'locations',
    fetchLocations,
    {
      select: (locations) =>
        locations.map(
          (loc) =>
            ({
              value: loc.LocationId?.toString() ?? '',
              label: loc.Name ?? '',
            } ?? [])
        ),
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of locations failed');
      },
    }
  );

  const editTicketMutation = useMutation<any, Error, Ticket>(
    'createTicket',
    (ticket) => PutTicket({ ...ticket, AssignUserId: ticket.AssignId }),
    {
      onSuccess: () => {
        refetch();
        setEditDetail(false);
        showOkToast('Edit successful');
        queryClient.invalidateQueries('tickets');
      },
      onError: () => {
        showErrorToast('Edit Failed');
      },
    }
  );

  const removeLabelMutation = useMutation<any, Error, number>(
    'removeLabel',
    (label) => removeEntityTagSingle(label),
    {
      onSuccess: () => {
        showOkToast('Removal of label successful');
        ticketTags.refetch();
      },
      onError: () => {
        showErrorToast('Label removal Failed');
      },
    }
  );

  const editReminderMutation = useMutation<any, Error, any>(
    'editReminder',
    (reminder) =>
      EditReminder(reminder.reminderId, reminder.dueDate, reminder.assignId),
    {
      onSuccess: () => {
        remindersQuery.refetch();
        showOkToast('Edit successful');
        queryClient.invalidateQueries('tickets');
      },
      onError: () => {
        showErrorToast('Edit Failed');
      },
    }
  );

  const cancelTicketMutation = useMutation<any, Error, Ticket>(
    'createTicket',
    () => CancelTicket(id),
    {
      onSuccess: () => {
        showOkToast('Edit successful');
        refetch().then(() => {
          setEditDetail(false);
        });
        queryClient.invalidateQueries('tickets');
      },
      onError: () => {
        showErrorToast('Ticket Cancellation Failed');
      },
    }
  );

  return (
    <>
      <LoadingOverlay
        visible={
          isLoading ||
          editTicketMutation.isLoading ||
          cancelTicketMutation.isLoading ||
          editReminderMutation.isLoading ||
          remindersQuery.isLoading
        }
      />
      <div className="bg-white p-4 rounded-lg mt-3">
        <div className="flex justify-between items-center">
          <span>{data?.FDSTicketId}</span>
          <>
            <ActionIcon
              className="ml-auto"
              onClick={() => setEditDetail(!editDetail)}
            >
              {canEdit &&
                (!editDetail ? (
                  <Pencil className="text-brand" />
                ) : (
                  <X className="text-brand" />
                ))}
            </ActionIcon>
            {data?.Status === TicketStatus.Complete && (
              <Badge variant="filled" color={'green'}>
                Completed
              </Badge>
            )}
            {data?.Status === TicketStatus.Open && (
              <Badge variant="filled" color={'yellow'}>
                Open
              </Badge>
            )}

            {data?.Status === TicketStatus.Cancelled && (
              <Badge variant="filled" color={'gray'}>
                Cancelled
              </Badge>
            )}
            {data?.Status === TicketStatus.InProgress && (
              <Badge variant="filled" color={'orange'}>
                Draft
              </Badge>
            )}
          </>
        </div>
        {editDetail ? (
          <>
            <TextInput
              placeholder="Ticket Title"
              {...register('Title')}
              className="my-3"
            />
            <Textarea
              placeholder="Description"
              {...register('Description')}
              className="mb-3"
            />
            <Controller
              control={control}
              name="Status"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  data={Object.values(TicketStatus).map((x) => ({
                    value: x,
                    label: x.toLocaleLowerCase(),
                  }))}
                  placeholder="Select assignee"
                  onChange={onChange}
                  onBlur={onBlur}
                  searchable
                  defaultValue={value}
                />
              )}
            />
          </>
        ) : (
          <>
            <h4 className="font-bold text-lg break-all">{data?.Title}</h4>
            <p className="break-all">{data?.Description}</p>
          </>
        )}
      </div>
      <div className="bg-white p-4 rounded-lg mt-3">
        <div className="pb-3">
          <span className="mr-3">Reporter</span>
          <span className="inline-block bg-gray-200 px-3 py-1 rounded break-all">
            {data?.CreateName}
          </span>
        </div>
        <div className="flex border-b-2 border-t-2 py-3">
          <Mail className="mr-2 text-brand" />
          <a href={`mailto:${data?.CreateEmail}`}>{data?.CreateEmail}</a>
        </div>
        <div className="flex pt-3">
          <Phone className="mr-2 text-brand" />
          <a href={`tel:${data?.CreatePhone}`}>{data?.CreatePhone}</a>
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg mt-3">
        <div className="flex items-center ">
          <span className="mr-3">Assignee</span>
          {editDetail ? (
            <Controller
              control={control}
              name="AssignId"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  data={usersQuery.data ?? []}
                  placeholder="Select assignee"
                  onChange={onChange}
                  onBlur={onBlur}
                  searchable
                  defaultValue={value}
                />
              )}
            />
          ) : (
            <span className="inline-block bg-gray-200 px-3 py-1 rounded break-all">
              {data?.AssignName}
            </span>
          )}
        </div>
        {!editDetail && (
          <>
            <div className="flex border-b-2 border-t-2 py-3 mt-3">
              <Mail className="mr-2 text-brand" />
              <a href={`mailto:${data?.AssignEmail}`}>{data?.AssignEmail}</a>
            </div>
            <div className="flex pt-3">
              <Phone className="mr-2 text-brand" />
              <a href={`tel:${data?.AssignPhone}`}>{data?.AssignPhone}</a>
            </div>
          </>
        )}
      </div>
      <div className="bg-white p-4 rounded-lg mt-3">
        <div className="pb-3 flex items-center">
          <span className="mr-3">Location</span>
          {editDetail ? (
            <Controller
              control={control}
              name="LocationId"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  data={locationsQuery.data ?? []}
                  placeholder="Enter Location"
                  onChange={(value) =>
                    onChange(value ? parseInt(value) : undefined)
                  }
                  onBlur={onBlur}
                  searchable
                  defaultValue={value?.toString()}
                />
              )}
            />
          ) : (
            <span className="inline-block bg-gray-200 px-3 py-1 rounded break-all">
              {data?.LocationName}
            </span>
          )}
        </div>
        {!!LocationId && (
          <div className="flex border-b-2 border-t-2 py-3">
            <span className="mr-3">Floor</span>
            {editDetail ? (
              <Controller
                control={control}
                name="FloorId"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    data={floorsQuery.data ?? []}
                    placeholder="Enter Floors"
                    onChange={(value) =>
                      onChange(value ? parseInt(value) : undefined)
                    }
                    onBlur={onBlur}
                    searchable
                    defaultValue={value?.toString()}
                  />
                )}
              />
            ) : (
              <span className="inline-block bg-gray-200 px-3 py-1 rounded break-all">
                {data?.FloorName}
              </span>
            )}
          </div>
        )}
        <div className="flex pt-3">
          <span className="mr-3">Area</span>
          {editDetail ? (
            <Controller
              control={control}
              name="AreaId"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  data={areasQuery.data ?? []}
                  placeholder="Enter Area"
                  onChange={(value) =>
                    onChange(value ? parseInt(value) : undefined)
                  }
                  onBlur={onBlur}
                  searchable
                  defaultValue={value?.toString()}
                />
              )}
            />
          ) : (
            <span className="inline-block bg-gray-200 px-3 py-1 rounded break-all">
              {data?.AreaName}
            </span>
          )}
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg mt-3">
        <div>
          <span className="mr-3">Posted</span>
          <span className="inline-block bg-gray-200 px-3 py-1 rounded break-all">
            {formatDistance(
              data?.CreateDate ? new Date(data.CreateDate) : new Date(),
              new Date(),
              { addSuffix: true }
            )}
          </span>
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg mt-3">
        <div className="flex items-start">
          <span className="mr-3 my-3">Labels</span>
          <Link to={'/start/ticket/label/' + id}>
            <ActionIcon className="my-3">
              <CirclePlus className="text-brand" />
            </ActionIcon>
          </Link>
          <div className="px-3 py-1 flex gap-2 flex-wrap">
            {ticketTags.data?.map((label) => (
              <div
                key={label.EntityTagId}
                className="flex bg-gray-200 p-2 pr-4 rounded"
              >
                <ActionIcon
                  onClick={() => removeLabelMutation.mutate(label.EntityTagId)}
                >
                  <CircleMinus size={16} className="text-brand" />
                </ActionIcon>
                {label.TagName}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg mt-3 flex items-start gap-x-3">
        <Bell size={32} className="mt-3" />
        <section className="flex-grow">
          {remindersQuery.data?.map((x) => (
            <div
              key={x.ReminderId}
              className="flex justify-between border-b-2 py-2"
            >
              <div>
                <div>
                  <span>Remind: </span>
                  <span className="font-bold">{x.AssignUser}</span>
                </div>
                <div>
                  <span>Due date: </span>
                  <span>{new Date(x.DueDate).toLocaleDateString()}</span>
                </div>
              </div>
              <ActionIcon
                className="ml-auto"
                onClick={() => navigate('reminder/' + x.ReminderId.toString())}
              >
                <Pencil size={36} className="text-brand" />
              </ActionIcon>
            </div>
          ))}
        </section>
      </div>
      {editDetail ? (
        <Button
          leftIcon={<Pencil />}
          fullWidth
          color="black"
          className="bg-brand mt-5"
          onClick={handleSubmit((data) => editTicketMutation.mutate(data))}
        >
          Update this ticket
        </Button>
      ) : (
        <Button
          leftIcon={<Bucket />}
          fullWidth
          color="black"
          className="bg-brand mt-5"
          onClick={handleSubmit((data) => cancelTicketMutation.mutate(data))}
          disabled={data?.Status === TicketStatus.Cancelled}
        >
          Cancel this ticket
        </Button>
      )}
    </>
  );
}
