import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    setTimeout(() => {
      console.log('scrolling');
      document.getElementById('view')?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 0);
  }, [pathname]);
  return null;
}
