import {
  Text,
  Title,
  Button,
  Stack,
  LoadingOverlay,
  ActionIcon,
  MultiSelect,
} from '@mantine/core';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { X, CircleMinus } from 'tabler-icons-react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';
import { EntityTags, Tags } from 'types/Tags';
import addEntityTagSingle from 'Mobile/api/Tags/attach';
import addTag from 'Mobile/api/Tags/create';
import fetchTags from 'Mobile/api/Tags/list';
import { removeEntityTagSingle } from 'Mobile/api/Tags/unattach';
import { fetchTicketTags } from 'Mobile/api/Tags/ticketTags';
import { useStore } from 'Shared/data/Store';
import { Controller, useForm } from 'react-hook-form';

function TicketLabels() {
  const { control, handleSubmit, setValue } = useForm<{
    labels: string[];
  }>({
    defaultValues: {
      labels: [],
    },
  });
  const navigate = useNavigate();
  const { queryStaleTimeMs } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
  }));

  const { id } = useParams();

  const queryClient = useQueryClient();
  const tags = useQuery<Tags[], Error>('labels', fetchTags, {
    staleTime: queryStaleTimeMs,
    onError: () => {
      showErrorToast('Fetching of labels failed');
    },
  });

  const ticketTags = useQuery<EntityTags[], Error>(
    ['ticketLabels', id],
    () => fetchTicketTags(parseInt(id ?? '')),
    {
      onError: () => {
        showErrorToast('Fetching of labels failed');
      },
      onSuccess: (data) => {
        setValue(
          'labels',
          data.map((x) => x.TagName)
        );
      },
    }
  );

  const addTagMutation = useMutation(addTag, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['labels'], (prev: any) => [
        {
          Id: data,
          TagName: variables,
        },
        ...prev,
      ]);
    },
    onError: () => {
      showErrorToast('Error Creating New Label');
    },
  });

  const mutation = useMutation<any, Error, string[]>(
    'appendTagToTicket',
    (labels) => {
      if (id && labels.length > 0) {
        const result = [];
        for (const label of labels) {
          if (ticketTags.data?.find((x) => x.TagName === label)) {
            continue;
          }
          const tag = tags.data?.find((x) => x.TagName === label);
          if (tag) {
            result.push(
              addEntityTagSingle(tag.Id, {
                FDSTicketId: parseInt(id),
              })
            );
          }
        }
        for (const tag of ticketTags.data ?? []) {
          if (!labels.includes(tag.TagName)) {
            result.push(removeEntityTagSingle(tag.EntityTagId));
          }
        }
        return Promise.all(result);
      }
      return Promise.resolve();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['ticketLabels', id]);
        showOkToast('Ticket labels has been updated');
        navigate('/tickets/id/' + id);
      },
      onError: () => {
        showErrorToast('Ticket labels update fails');
      },
    }
  );

  return (
    <section>
      <LoadingOverlay
        visible={
          mutation.isLoading ||
          addTagMutation.isLoading ||
          ticketTags.isLoading ||
          tags.isLoading
        }
      />
      <div className="flex justify-between items-center">
        <Title>Add new ticket</Title>
        <Link
          to={'/tickets/id/' + id}
          className="text-brand inline-flex items-center"
        >
          Close <X />
        </Link>
      </div>
      <Text>Create labels for this ticket</Text>
      <Stack className="mt-5">
        <Controller
          control={control}
          name="labels"
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <MultiSelect
                data={
                  tags.data?.map((tag) => ({
                    value: tag.TagName,
                    label: tag.TagName,
                  })) ?? []
                }
                value={value}
                placeholder="Select tags"
                onChange={onChange}
                getCreateLabel={(query) => `+ Create ${query}`}
                onCreate={addTagMutation.mutate}
                onBlur={onBlur}
                searchable
                creatable
              />
              <div className="flex flex-wrap gap-3">
                {value.map((label) => (
                  <div key={label} className="flex bg-white p-2">
                    {label}
                    <ActionIcon
                      onClick={() => onChange(value.filter((x) => x !== label))}
                    >
                      <CircleMinus size={16} className="text-brand" />
                    </ActionIcon>
                  </div>
                ))}
              </div>
            </>
          )}
        />
        <Button
          fullWidth
          color="black"
          className="bg-brand mt-10 active:bg-brand"
          onClick={handleSubmit((e) => mutation.mutate(e.labels))}
        >
          Save labels
        </Button>
      </Stack>
    </section>
  );
}

export default TicketLabels;
