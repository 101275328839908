import { css } from '@emotion/react';
import {
  ActionIcon,
  Badge,
  Button,
  List,
  Loader,
  LoadingOverlay,
  SegmentedControl,
  TextInput,
  Title,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import ListReminders from 'Mobile/api/Reminders/List';
import Pagination from 'Mobile/components/Pagination';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useStore } from 'Shared/data/Store';
import { showErrorToast } from 'Shared/helpers/ui';
import {
  BellRinging,
  ChevronDown,
  ChevronUp,
  Eye,
  Search,
  Ticket,
  X,
} from 'tabler-icons-react';
import { Reminder } from 'types/Reminders';
import { tailwindConfig } from 'Shared/helpers/styles';

interface Props {
  active?: boolean;
}
const segmentStyle = css`
  label {
    color: ${(tailwindConfig.theme?.textColor as any)?.brand};
    &.mantine-SegmentedControl-labelActive {
      font-weight: bold;
    }
  }
`;

function Reminders({ active }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [activeInput, setActiveInput] = useState(false);
  const [perPage, setPerPage] = useState(location.state?.perPage ?? 10);
  const [currentPage, setCurrentPage] = useState(
    location.state?.currentPage ?? 1
  );
  const [searchTemp, setSearchTemp] = useState('');
  const [debouncedSearch] = useDebouncedValue(searchTemp, 300);

  const { queryStaleTimeMs } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
  }));

  const { isLoading, data } = useQuery<
    { count: number; value: Reminder[] },
    Error
  >(
    ['reminders', debouncedSearch, active, perPage, currentPage],
    () => ListReminders(debouncedSearch, active, perPage, currentPage),
    {
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of tickets failed');
      },
    }
  );

  return (
    <div className="pt-32">
      <LoadingOverlay visible={isLoading} />
      <div className="fixed bg-gray-100 z-10 top-0 pt-6 pb-2 left-0 right-0 px-8">
        <div className="flex justify-between items-center">
          <Title>Reminders</Title>
          <SegmentedControl
            css={segmentStyle}
            data={[
              { label: 'Active', value: '/reminders' },
              { label: 'Passed', value: '/reminders/passed' },
            ]}
            radius="xl"
            onChange={navigate}
            value={active ? '/reminders' : '/reminders/passed'}
          />
        </div>
        <div className="flex gap-x-2 mt-3">
          <TextInput
            icon={<Search className="text-black" />}
            placeholder="Search Reminders"
            className="grow relative"
            type="search"
            onChange={(e) => {
              setSearchTemp(e.currentTarget.value);
            }}
            onFocus={() => {
              setActiveInput(true);
            }}
            rightSection={
              activeInput && (
                <Button
                  className="text-gray-400"
                  onClick={() => {
                    setActiveInput(false);
                  }}
                >
                  <X />
                </Button>
              )
            }
          />
        </div>
      </div>
      {data?.value.map((reminder) => (
        <div className="mb-3 bg-white p-3 rounded-lg" key={reminder.ReminderId}>
          <Link
            to={`/reminders/${active ? 'active' : 'passed'}/${
              reminder.ReminderId?.toString() === id
                ? ''
                : reminder.ReminderId?.toString()
            }`}
            className="flex items-center"
            state={{
              perPage,
              currentPage,
            }}
          >
            <BellRinging className={active ? 'text-brand' : 'text-greyDark'} />
            <h4 className="font-bold text-lg ml-3 mr-auto">{reminder.Title}</h4>
            {reminder.ReminderId?.toString() === id ? (
              <ChevronDown className="text-brand" />
            ) : (
              <ChevronUp className="text-brand" />
            )}
          </Link>
          {reminder.ReminderId?.toString() === id && (
            <>
              <div>
                <span className="text-xs text-gray-400 mr-3 ml-9">
                  Due date
                </span>
                <Badge color="gray" variant="filled" className="text-black">
                  {new Date((reminder.DueDate ?? '') as string)
                    ?.toLocaleDateString()
                    .replaceAll('/', '.')}
                </Badge>
              </div>
              <div className="flex space-x-3 mt-3 bg-gray-200 rounded p-2 items-center">
                <Ticket />
                <span className="mr-auto">
                  Ticket Attached - {reminder.TicketTitle}
                </span>
                <ActionIcon
                  to={`/tickets/id/${reminder.TicketId}`}
                  component={Link}
                >
                  <Eye className="text-brand" />
                </ActionIcon>
              </div>
            </>
          )}
        </div>
      ))}
      <Pagination
        perPage={perPage}
        currentPage={currentPage}
        onChange={(perPage, currentPage) => {
          setPerPage(perPage);
          setCurrentPage(currentPage);
        }}
        totalPages={Math.ceil((data?.count ?? 0) / perPage)}
      />
    </div>
  );
}

export default Reminders;
