import config from 'config';
import fetchWithAuth from 'Shared/helpers/fetch';
import { Ticket } from 'types/Ticket';

export default async function PutTicket(ticket: Ticket): Promise<number> {
  return fetchWithAuth(
    `${config.apiBaseUrl}/Api/Data/FDSTicket/${ticket.FDSTicketId}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...ticket }),
    }
  ).then((data) => data);
}
