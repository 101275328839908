import { NumberInput, ActionIcon, Select } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { ChevronsLeft, ChevronsRight } from 'tabler-icons-react';

const getNumber = (pageNumber: number, totalPages: number) => {
  const arr = [];
  const start = pageNumber > 1 ? pageNumber - 1 : pageNumber;
  for (let i = start; i <= totalPages && i < start + 3; i++) {
    arr.push(i);
  }
  return arr;
};

interface PaginationProps {
  onChange: (perPage: number, currentPage: number) => void;
  perPage: number;
  currentPage: number;
  totalPages: number;
}
export default function Pagination({
  onChange,
  perPage,
  currentPage,
  totalPages,
}: PaginationProps) {
  const [perPages, setPerPages] = useState(perPage);
  const [debounced] = useDebouncedValue(perPages, 500);

  useEffect(() => {
    onChange(debounced, currentPage);
  }, [debounced]);
  return (
    <div className="flex py-5 items-end gap-x-4">
      <div className="flex items-center gap-x-1">
        <Select
          label="Results per page"
          data={['10', '25', '35', '50']}
          defaultValue={perPage.toString()}
          onChange={(val) => setPerPages(parseInt(val ?? '10'))}
        />
      </div>
      <div className="grow flex gap-x-1">
        <ActionIcon
          radius="xl"
          size="lg"
          className="bg-white shadow-lg text-brand"
          disabled={currentPage <= 1}
          onClick={() => onChange(perPage, 1)}
        >
          <ChevronsLeft />
        </ActionIcon>
        {getNumber(currentPage, totalPages).map((x) => (
          <ActionIcon
            key={x}
            radius="xl"
            size="lg"
            className={
              x === currentPage
                ? 'text-white shadow-lg bg-brand'
                : 'bg-white shadow-lg text-brand'
            }
            onClick={() => onChange(perPage, x)}
          >
            {x}
          </ActionIcon>
        ))}
        <ActionIcon
          radius="xl"
          size="lg"
          className="bg-white shadow-lg text-brand"
          disabled={currentPage >= totalPages}
          onClick={() => onChange(perPage, totalPages)}
        >
          <ChevronsRight />
        </ActionIcon>
      </div>
    </div>
  );
}
