import { Button, Drawer, Title, Text, ActionIcon } from '@mantine/core';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  CloudUpload,
  ExclamationMark,
  FileX,
  Folders,
  X,
} from 'tabler-icons-react';

interface UploadProps {
  onSave: (files: File[]) => void;
  opened: boolean;
  onClose: () => void;
  clearAfterSave: boolean;
  files?: File[];
}
export default function Upload({
  onSave,
  opened,
  onClose,
  clearAfterSave = false,
  files,
}: UploadProps) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const [images, setImages] = useState<File[]>([]);
  const [documents, setDocuments] = useState<File[]>([]);
  const isFirstRender = useRef(true);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      separateFiles(e.target.files);
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      if (files && files.length > 0) {
        separateFiles(files);
      }
    }
  }, [files]);
  const separateFiles = (files: FileList | File[]) => {
    let tempImages: File[] = [];
    let tempDocuments: File[] = [];
    for (const file of files) {
      if (file.type.startsWith('image/')) {
        tempImages = [...tempImages, file];
      } else {
        tempDocuments = [...tempDocuments, file];
      }
    }
    setImages((images) => [...tempImages, ...images]);
    setDocuments((documents) => [...tempDocuments, ...documents]);
  };
  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      size="full"
      withCloseButton={false}
      padding="lg"
      className="bg-gray-100 overflow-auto"
    >
      <div className="flex justify-between items-center">
        <Title>Upload files</Title>
        <Button
          className="text-brand inline-flex items-center"
          onClick={onClose}
        >
          Close <X />
        </Button>
      </div>
      <Text className="border-b-1">Select photos or documents</Text>
      <div className="bg-white rounded shadow-sm p-5 mt-8">
        <Button
          onClick={() => hiddenFileInput.current?.click()}
          className="text-brand text-lg border-brand"
          fullWidth
          leftIcon={<Folders />}
        >
          Browse
        </Button>
        <input
          type="file"
          ref={hiddenFileInput}
          className="hidden"
          multiple
          onChange={handleChange}
          accept=".jpg,.jpeg,.png,.pdf,.docx,.xlsx,.heic"
        />
        <section className="mt-5 flex flex-col gap-y-3">
          {documents.map((x, i) => (
            <div key={i} className="bg-white p-3 break-all rounded shadow-sm">
              <div className="flex gap-x-3">
                <FileX />
                {x.name}
                {x.size < 10485760 && (
                  <ActionIcon
                    className="text-brand ml-auto"
                    onClick={() => {
                      setDocuments((documents) =>
                        documents.filter((document) => document !== x)
                      );
                    }}
                  >
                    <X />
                  </ActionIcon>
                )}
              </div>
              {x.size > 10485760 && (
                <span className="text-brand inline-flex">
                  <ExclamationMark />
                  File too large
                </span>
              )}
            </div>
          ))}
        </section>
        <section className="mt-3 grid grid-cols-2 gap-3">
          {images.map((x, i) => (
            <div className="bg-white p-3 rounded shadow-sm" key={i}>
              <img src={URL.createObjectURL(x)} />
              <div className="flex justify-between mt-2">
                <p className="truncate">{x.name}</p>
                {x.size < 10485760 && (
                  <ActionIcon
                    className="text-brand ml-auto"
                    onClick={() => {
                      setImages((images) =>
                        images.filter((image) => image !== x)
                      );
                    }}
                  >
                    <X />
                  </ActionIcon>
                )}
              </div>
              {x.size > 10485760 && (
                <span className="text-brand inline-flex">
                  <ExclamationMark />
                  File too large
                </span>
              )}
            </div>
          ))}
        </section>
        {(documents.length > 0 || images.length > 0) && (
          <Button
            leftIcon={<CloudUpload />}
            fullWidth
            color="black"
            className="bg-brand mt-10"
            onClick={() => {
              onSave(images.concat(documents).filter((x) => x.size < 10485760));
              if (clearAfterSave) {
                setImages([]);
                setDocuments([]);
              }
            }}
          >
            Save Files
          </Button>
        )}
      </div>
    </Drawer>
  );
}
