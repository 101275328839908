import { Accordion, Button, LoadingOverlay } from '@mantine/core';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import AnswerChecklistQuestion from 'Mobile/api/Checklist/answerQuestion';
import { GetChecklistSections } from 'Mobile/api/Checklist/section';
import { useStore } from 'Shared/data/Store';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';
import {
  Camera,
  ChevronDown,
  ChevronUp,
  CircleCheck,
  Ticket,
} from 'tabler-icons-react';
import { Checklist, EnumCheckListAnswer } from 'types/Checklist';

interface Props {
  id: number;
}
interface AnswerQuestion {
  id: number;
  answer: EnumCheckListAnswer;
}
export default function SectionTab({ id }: Props) {
  const { sectionId } = useParams();
  const { queryStaleTimeMs } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
  }));
  const { data, isLoading } = useQuery<Checklist, Error>(
    ['checklistSection', id],
    () => GetChecklistSections(id),
    {
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of Checklist failed');
      },
    }
  );
  const queryClient = useQueryClient();
  const mutation = useMutation<any, Error, AnswerQuestion>(
    'answerChecklistQuestion',
    (question) => AnswerChecklistQuestion(question.id, question.answer),
    {
      onSuccess: (res, params) => {
        const section = data?.Sections.map((section) => ({
          ...section,
          Questions: section.Questions.map((question) => {
            if (question.CheckListQuestionId === params.id) {
              return {
                ...question,
                Answer: params.answer,
              };
            } else {
              return question;
            }
          }),
        }));
        queryClient.setQueryData(['checklistSection', id], {
          Sections: section,
        });
        showOkToast('Answer has been recorded');
      },
      onError: () => {
        showErrorToast('Answer failed');
      },
    }
  );
  return (
    <section className="-mx-8">
      <LoadingOverlay visible={isLoading || mutation.isLoading} />
      {data?.Sections.map((section) => (
        <>
          <Link
            to={`/checklists/id/${id}/section/${section.CheckListSectionId}`}
            className="flex p-4 bg-white font-bold"
            key={section.CheckListSectionId}
          >
            <CircleCheck className="text-brand mr-2" />
            {section.SectionName}
            {sectionId === section.CheckListSectionId.toString() ? (
              <ChevronDown className="ml-auto" />
            ) : (
              <ChevronUp className="ml-auto" />
            )}
          </Link>
          {sectionId === section.CheckListSectionId.toString() && (
            <div className="bg-gray-100 py-5 px-5">
              <Accordion iconPosition="right">
                {section.Questions.map((question) => (
                  <Accordion.Item
                    key={question.CheckListQuestionId}
                    label={
                      <span className="flex">
                        <CircleCheck className="text-brand mr-2" />{' '}
                        {question.Question}
                      </span>
                    }
                    className="bg-white"
                  >
                    <form className="-mx-4 mb-4">
                      <div className="flex border-y-2 mt-3 justify-evenly text-xl">
                        <label className="flex-grow text-center py-3">
                          <input
                            type="radio"
                            value=""
                            className="mr-2"
                            name="test"
                            defaultChecked={
                              question.Answer === EnumCheckListAnswer.Passed
                            }
                            onChange={(e) => {
                              if (e.currentTarget.checked) {
                                mutation.mutate({
                                  id: question.CheckListQuestionId,
                                  answer: EnumCheckListAnswer.Passed,
                                });
                              }
                            }}
                          />
                          <br />
                          Passed
                        </label>
                        <label className="flex-grow text-center py-3 border-x-2 ">
                          <input
                            type="radio"
                            value="desc"
                            className="mr-2"
                            name="test"
                            defaultChecked={
                              question.Answer === EnumCheckListAnswer.Failed
                            }
                            onChange={(e) => {
                              if (e.currentTarget.checked) {
                                mutation.mutate({
                                  id: question.CheckListQuestionId,
                                  answer: EnumCheckListAnswer.Failed,
                                });
                              }
                            }}
                          />
                          <br />
                          Failed
                        </label>
                        <label className="flex-grow text-center py-3">
                          <input
                            type="radio"
                            value="desc"
                            className="mr-2"
                            name="test"
                            defaultChecked={
                              question.Answer === null ||
                              question.Answer ===
                                EnumCheckListAnswer.NotApplicable
                            }
                            onChange={(e) => {
                              if (e.currentTarget.checked) {
                                mutation.mutate({
                                  id: question.CheckListQuestionId,
                                  answer: EnumCheckListAnswer.NotApplicable,
                                });
                              }
                            }}
                          />
                          <br />
                          N/A
                        </label>
                      </div>
                      <div className="my-2">
                        <Link
                          className="text-black bg-white active:bg-white inline-flex ml-5"
                          to={`attachment/${question.CheckListQuestionId}`}
                        >
                          <Camera className="mr-2" /> Upload photo
                        </Link>
                      </div>
                      {question.Answer !== EnumCheckListAnswer.Passed && (
                        <div>
                          <Link
                            to={`/start/checklist/${id}/ticket`}
                            className="text-black bg-white active:bg-white flex mx-5"
                          >
                            <Ticket className="mr-2" /> Attach Ticket
                          </Link>
                        </div>
                      )}
                    </form>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          )}
        </>
      ))}
    </section>
  );
}
