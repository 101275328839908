import { Button, LoadingOverlay, PasswordInput } from '@mantine/core';
import changePassword from 'Mobile/api/Users/ResetPassword';
import Modal from 'Mobile/components/Modal';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';
import { Lock } from 'tabler-icons-react';

interface PasswordType {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export default function Password() {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<PasswordType>();

  const mutation = useMutation<any, Error, PasswordType>(
    'updateProfile',
    (pwd) =>
      changePassword(pwd.oldPassword, pwd.newPassword, pwd.confirmPassword),
    {
      onSuccess: () => {
        showOkToast('Password has been updated');
        navigate('/settings');
      },
      onError: () => {
        showErrorToast('Password update Failed');
      },
    }
  );
  return (
    <Modal onClose={() => navigate('/settings')} title="Password">
      <LoadingOverlay visible={mutation.isLoading} />
      <form onSubmit={handleSubmit((data) => mutation.mutate(data))}>
        <div>
          <label htmlFor="oldPassword">Current Password</label>
          <PasswordInput
            {...register('oldPassword')}
            size="lg"
            radius="md"
            className="shadow"
            icon={<Lock className="text-black" />}
          />
        </div>
        <div className="mt-4">
          <label htmlFor="newPassword">New Password</label>
          <PasswordInput
            {...register('newPassword')}
            size="lg"
            radius="md"
            className="shadow"
            icon={<Lock className="text-black" />}
          />
        </div>
        <div className="mt-4">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <PasswordInput
            {...register('confirmPassword')}
            size="lg"
            radius="md"
            className="shadow"
            icon={<Lock className="text-black" />}
          />
        </div>
        <Button
          fullWidth
          className="bg-brand mt-10 mb-3"
          size="lg"
          type="submit"
        >
          Update Password
        </Button>
      </form>
    </Modal>
  );
}
