import fetchWithAuth from 'Shared/helpers/fetch';
import config from 'config';
import buildQuery from 'odata-query';

const GetChecklistTemplate = () => {
  const query = buildQuery({
    select: ['CheckListTemplateId', 'TemplateName'],
  });
  return fetchWithAuth(
    `${config.apiBaseUrl}/Api/ChecklistTemplates/odata${query}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  ).then((data) => data.value);
};
export default GetChecklistTemplate;
