import { Route, Routes } from 'react-router-dom';
import Settings from './settings';
import Password from './password';

export default function Index() {
  return (
    <Routes>
      <Route index path="" element={<Settings />} />
      <Route path="/password" element={<Password />} />
    </Routes>
  );
}
