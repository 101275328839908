import {
  ActionIcon,
  Badge,
  Button,
  LoadingOverlay,
  Select,
} from '@mantine/core';
import { formatDistance } from 'date-fns';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useStore } from 'Shared/data/Store';
import { GetTicket } from 'Mobile/api/Tickets/Get';
import fetchAllUsers from 'Mobile/api/Users/list';
import { Mail, Phone, Bell, Link as LinkIcon } from 'tabler-icons-react';
import { Ticket, TicketReminderDTO, TicketStatus } from 'types/Ticket';
import { User } from 'types/Users';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';
import { fetchTicketTags } from 'Mobile/api/Tags/ticketTags';
import { removeEntityTagSingle } from 'Mobile/api/Tags/unattach';
import { Link } from 'react-router-dom';
import { EditReminder } from 'Mobile/api/Reminders/EditReminder';
import GetTicketReminders from 'Mobile/api/Reminders/GetTicketReminders';
import { EntityTags } from 'types/Tags';

interface DetailProps {
  id: number;
}

interface SelectData {
  value: string;
  label: string;
}

export default function TicketTab({ id }: DetailProps) {
  const [openReminder, setOpenReminder] = useState<number>();
  const { queryStaleTimeMs } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
  }));

  const { isLoading, data } = useQuery<Ticket, Error>(
    ['ticket', id],
    () => GetTicket({ CheckListId: id }),
    {
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of Ticket failed');
      },
    }
  );

  const ticketTags = useQuery<EntityTags[], Error>(
    ['ticketLabels', data?.FDSTicketId],
    () => fetchTicketTags(data?.FDSTicketId),
    {
      onError: () => {
        showErrorToast('Fetching of labels failed');
      },
    }
  );

  const remindersQuery = useQuery<TicketReminderDTO[], Error>(
    ['ticketReminders', data?.FDSTicketId],
    () => GetTicketReminders(data?.FDSTicketId),
    {
      staleTime: queryStaleTimeMs,
      enabled: !!id,
      onError: () => {
        showErrorToast('Fetching of Ticket Reminders failed');
      },
    }
  );

  const editReminderMutation = useMutation<any, Error, any>(
    'editReminder',
    (reminder) =>
      EditReminder(reminder.reminderId, reminder.dueDate, reminder.assignId),
    {
      onSuccess: () => {
        remindersQuery.refetch();
        setOpenReminder(undefined);
        showOkToast('Edit successful');
      },
      onError: () => {
        showErrorToast('Edit Failed');
      },
    }
  );

  return (
    <>
      <LoadingOverlay
        visible={
          isLoading ||
          editReminderMutation.isLoading ||
          remindersQuery.isLoading
        }
      />
      <div className="bg-white p-4 rounded-lg mt-3">
        <div className="flex justify-between items-center">
          <span>{data?.FDSTicketId}</span>
          <>
            <ActionIcon
              className="ml-auto"
              component={Link}
              to={'/tickets/id/' + data?.FDSTicketId}
            >
              <LinkIcon className="text-brand" />
            </ActionIcon>
            {data?.Status === TicketStatus.Complete && (
              <Badge variant="filled" color={'green'}>
                Completed
              </Badge>
            )}
            {data?.Status === TicketStatus.Open && (
              <Badge variant="filled" color={'yellow'}>
                Open
              </Badge>
            )}

            {data?.Status === TicketStatus.Cancelled && (
              <Badge variant="filled" color={'gray'}>
                Cancelled
              </Badge>
            )}
            {data?.Status === TicketStatus.InProgress && (
              <Badge variant="filled" color={'orange'}>
                Draft
              </Badge>
            )}
          </>
        </div>
        <h4 className="font-bold text-lg">{data?.Title}</h4>
        <p>{data?.Description}</p>
      </div>
      <div className="bg-white p-4 rounded-lg mt-3">
        <div className="pb-3">
          <span className="mr-3">Reporter</span>
          <span className="inline-block bg-gray-200 px-3 py-1 rounded break-all">
            {data?.CreateName}
          </span>
        </div>
        <div className="flex border-b-2 border-t-2 py-3">
          <Mail className="mr-2 text-brand" />
          <a href={`mailto:${data?.CreateEmail}`}>{data?.CreateEmail}</a>
        </div>
        <div className="flex pt-3">
          <Phone className="mr-2 text-brand" />
          <a href={`tel:${data?.CreatePhone}`}>{data?.CreatePhone}</a>
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg mt-3">
        <div className="flex items-center ">
          <span className="mr-3">Assignee</span>
          <span className="inline-block bg-gray-200 px-3 py-1 rounded break-all">
            {data?.AssignName}
          </span>
        </div>
        <div className="flex border-b-2 border-t-2 py-3 mt-3">
          <Mail className="mr-2 text-brand" />
          <a href={`mailto:${data?.AssignEmail}`}>{data?.AssignEmail}</a>
        </div>
        <div className="flex pt-3">
          <Phone className="mr-2 text-brand" />
          <a href={`tel:${data?.AssignPhone}`}>{data?.AssignPhone}</a>
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg mt-3">
        <div className="pb-3 flex items-center">
          <span className="mr-3">Location</span>

          <span className="inline-block bg-gray-200 px-3 py-1 rounded break-all">
            {data?.LocationName}
          </span>
        </div>
        <div className="flex border-b-2 border-t-2 py-3">
          <span className="mr-3">Floor</span>

          <span className="inline-block bg-gray-200 px-3 py-1 rounded break-all">
            {data?.FloorName}
          </span>
        </div>
        <div className="flex pt-3">
          <span className="mr-3">Area</span>

          <span className="inline-block bg-gray-200 px-3 py-1 rounded break-all">
            {data?.AreaName}
          </span>
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg mt-3">
        <div>
          <span className="mr-3">Posted</span>
          <span className="inline-block bg-gray-200 px-3 py-1 rounded break-all">
            {formatDistance(
              data?.CreateDate ? new Date(data?.CreateDate) : new Date(),
              new Date(),
              { addSuffix: true }
            )}
          </span>
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg mt-3">
        <div className="flex items-start">
          <span className="mr-3 my-3">Labels</span>
          <div className="px-3 py-1 flex gap-2 flex-wrap">
            {ticketTags.data?.map((label) => (
              <div
                key={label.EntityTagId}
                className="flex bg-gray-200 p-2 pr-4 rounded"
              >
                {label.TagName}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg mt-3 flex items-start gap-x-3">
        <Bell size={32} className="mt-3" />
        <section className="flex-grow">
          {remindersQuery.data?.map((x) => (
            <div
              key={x.ReminderId}
              className="flex justify-between border-b-2 py-2"
            >
              <div>
                <div>
                  <span>Remind: </span>
                  <span className="font-bold">{x.AssignUser}</span>
                </div>
                <div>
                  <span>Due date: </span>
                  <span>{new Date(x.DueDate).toLocaleDateString()}</span>
                </div>
              </div>
            </div>
          ))}
        </section>
      </div>
    </>
  );
}
