import config from 'config';
import buildQuery from 'odata-query';
import fetchWithAuth from 'Shared/helpers/fetch';

const ListReminders = async (
  search: string | undefined,
  active: boolean | undefined,
  perPage: number,
  currentPage: number
) => {
  const filter: any[] = [
    {
      AssignUserId: localStorage.getItem('userId'),
    },
  ];
  if (search) {
    filter.push({
      or: {
        [`indexof(Title, '${search}')`]: {
          gt: -1,
        },
      },
    });
  }
  if (active) {
    filter.push({
      DueDate: {
        ge: new Date(),
      },
    });
  } else {
    filter.push({
      DueDate: {
        lt: new Date(),
      },
    });
  }
  const query = buildQuery({
    count: true,
    filter,
    top: perPage,
    skip: perPage * (currentPage - 1),
    select: ['ReminderId', 'Title', 'DueDate', 'TicketId', 'TicketTitle'],
  });
  return fetchWithAuth(`${config.apiBaseUrl}/Api/Reminders/odata${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((data) => ({
    count: data['@odata.count'],
    value: data.value,
  }));
};

export default ListReminders;
