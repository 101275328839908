import { ActionIcon, List, MultiSelect, Select, Button } from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { fetchLocations } from 'Mobile/api/Locations/location';
import fetchTags from 'Mobile/api/Tags/list';
import fetchAllUsers from 'Mobile/api/Users/list';
import Modal from 'Mobile/components/Modal';
import SelectData from 'Mobile/helpers/selectData';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'Shared/data/Store';
import { showErrorToast } from 'Shared/helpers/ui';
import { Calendar, CircleMinus } from 'tabler-icons-react';
import { ChecklistFilterDTO, ChecklistStatus } from 'types/Checklist';
import { Location } from 'types/Locations';
import { Tags } from 'types/Tags';
import { User } from 'types/Users';

export default function Filter() {
  const navigate = useNavigate();
  const location = useLocation();
  const { queryStaleTimeMs } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
  }));
  const { handleSubmit, control } = useForm<ChecklistFilterDTO>({
    defaultValues: location.state,
  });

  const locationsQuery = useQuery<Location[], Error, SelectData[]>(
    'locations',
    fetchLocations,
    {
      select: (locations) =>
        locations.map((loc) => ({
          value: loc.LocationId?.toString() ?? '',
          label: loc.Name ?? '',
        })) ?? [],
      onError: () => {
        showErrorToast('Fetching of locations failed');
      },
    }
  );
  const tags = useQuery<Tags[], Error, SelectData[]>('labels', fetchTags, {
    select: (tags) =>
      tags.map((tag) => ({
        value: tag.TagName.toString() ?? '',
        label: tag.TagName,
      })) ?? [],
    onError: () => {
      showErrorToast('Fetching of labels failed');
    },
  });

  const usersQuery = useQuery<User[], Error, SelectData[]>(
    'users',
    fetchAllUsers,
    {
      select: (users) =>
        users.map((user) => ({
          value: user.Id ?? '',
          label: user.FirstName + ' ' + user.Surname,
        })) ?? [],
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of users failed');
      },
    }
  );

  return (
    <Modal
      onClose={() =>
        navigate('/checklists', {
          state: location.state,
        })
      }
      title="Filters"
    >
      <form
        className="py-6 rounded-lg mt-3"
        onSubmit={handleSubmit((e) =>
          navigate('/checklists', {
            state: e,
          })
        )}
      >
        <div className="bg-white h-50 pt-5 pb-5 rounded-lg shadow-sm">
          <Controller
            control={control}
            name="Locations"
            render={({ field: { onChange, onBlur, value } }) => (
              <div className="px-4">
                <MultiSelect
                  label="Locations"
                  data={locationsQuery.data ?? []}
                  placeholder="Select locations"
                  onChange={(value) => onChange(value)}
                  onBlur={onBlur}
                  value={value}
                  searchable
                  clearable
                />
                <List className="mt-5">
                  {(value ?? []).map((location) => (
                    <List.Item
                      key={location}
                      icon={
                        <ActionIcon
                          onClick={() =>
                            onChange(value.filter((x) => x !== location))
                          }
                        >
                          <CircleMinus size={16} className="text-brand" />
                        </ActionIcon>
                      }
                    >
                      {
                        locationsQuery.data?.find((x) => x.value === location)
                          ?.label
                      }
                    </List.Item>
                  ))}
                </List>
              </div>
            )}
          />
          <hr />
          <Controller
            control={control}
            name="Labels"
            render={({ field: { onChange, onBlur, value } }) => (
              <div className="px-4 mt-3">
                <MultiSelect
                  label="Labels"
                  data={tags.data ?? []}
                  placeholder="Select labels"
                  onChange={(value) => onChange(value)}
                  onBlur={onBlur}
                  value={value}
                  searchable
                />
                <List className="mt-5 mb-3">
                  {(value ?? []).map((label) => (
                    <List.Item
                      key={label}
                      icon={
                        <ActionIcon
                          onClick={() =>
                            onChange(value.filter((x) => x !== label))
                          }
                        >
                          <CircleMinus size={16} className="text-brand" />
                        </ActionIcon>
                      }
                    >
                      {label}
                    </List.Item>
                  ))}
                </List>
              </div>
            )}
          />
          <hr />
          <Controller
            control={control}
            name="Status"
            render={({ field: { onChange, onBlur, value } }) => (
              <div>
                <Select
                  data={Object.values(ChecklistStatus).map((x) => ({
                    value: x,
                    label: x.toLocaleLowerCase(),
                  }))}
                  label="Checklist status"
                  placeholder="Select status"
                  onChange={onChange}
                  onBlur={onBlur}
                  searchable
                  defaultValue={value}
                  clearable
                  className="px-4 mt-3"
                />
              </div>
            )}
          />
        </div>
        <div className="bg-white h-50 pt-5 pb-5 rounded-lg shadow-sm my-5 px-4 grid grid-cols-2 gap-x-2">
          <Controller
            control={control}
            name="CreateDate"
            render={({ field: { onChange, onBlur, value } }) => (
              <DateRangePicker
                label="Create date range"
                icon={<Calendar className="text-brand" />}
                placeholder="Create date"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="DueDate"
            render={({ field: { onChange, onBlur, value } }) => (
              <DateRangePicker
                label="Due date range"
                value={value}
                icon={<Calendar className="text-brand" />}
                placeholder="Due date"
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </div>
        <div className="bg-white h-50 pt-5 pb-5 rounded-lg shadow-sm px-4">
          <Controller
            control={control}
            name="Reporter"
            render={({ field: { onChange, onBlur, value } }) => (
              <div>
                <Select
                  data={usersQuery.data ?? []}
                  label="Reporter"
                  placeholder="Select reporter"
                  onChange={onChange}
                  onBlur={onBlur}
                  searchable
                  clearable
                  defaultValue={value}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="Assignee"
            render={({ field: { onChange, onBlur, value } }) => (
              <div>
                <Select
                  data={usersQuery.data ?? []}
                  label="Assignee"
                  placeholder="Select asignee"
                  onChange={onChange}
                  onBlur={onBlur}
                  searchable
                  defaultValue={value}
                  className="mt-3"
                  clearable
                />
              </div>
            )}
          />
        </div>
        <Button
          size="lg"
          fullWidth
          color="black"
          className="bg-brand mt-10"
          type="submit"
        >
          Apply filters
        </Button>
      </form>
    </Modal>
  );
}
