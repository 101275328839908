import {
  Text,
  Textarea,
  TextInput,
  Title,
  Select,
  Button,
  Stack,
  LoadingOverlay,
  ActionIcon,
} from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { X, MapPin, CirclePlus, CircleMinus } from 'tabler-icons-react';
import { fetchLocations } from 'Mobile/api/Locations/location';
import { Location } from 'types/Locations';
import { useMutation, useQuery } from 'react-query';
import { useStore } from 'Shared/data/Store';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';
import CreateChecklist from 'Mobile/api/Checklist/create';
import { Tags } from 'types/Tags';
import addEntityTagSingle from 'Mobile/api/Tags/attach';
import fetchTags from 'Mobile/api/Tags/list';
import { Checklist } from 'types/Checklist';

interface SelectData {
  value: string;
  label: string;
}

type FormType = Checklist & { Tags: string[] };

function Checklists() {
  const locations = useLocation();
  const { register, handleSubmit, control, getValues } = useForm<FormType>({
    defaultValues: locations.state,
  });

  const { id } = useParams();
  const navigate = useNavigate();
  const { queryStaleTimeMs } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
  }));

  const locationsQuery = useQuery<Location[], Error, SelectData[]>(
    'locations',
    fetchLocations,
    {
      select: (locations) =>
        locations.map(
          (loc) =>
            ({
              value: loc.LocationId?.toString() ?? '',
              label: loc.Name ?? '',
            } ?? [])
        ),
      staleTime: queryStaleTimeMs,
      onError: () => {
        showErrorToast('Fetching of locations failed');
      },
    }
  );

  const mutation = useMutation<any, Error, FormType>(
    'createChecklist',
    (ticket) => {
      const { Tags, ...checklist } = ticket;
      if (!id) {
        throw Error('No Template selected');
      } else {
        return CreateChecklist({
          ...checklist,
          TemplateId: id,
        }).then(async (value) => {
          const result = [];
          if (Tags)
            for (const tag of Tags) {
              const id = tags.data?.find((x) => x.TagName === tag)?.Id;
              if (id)
                result.push(addEntityTagSingle(id, { CheckListId: value }));
            }
          await Promise.all(result);
          return value;
        });
      }
    },
    {
      onSuccess: (checklistId) => {
        showOkToast('Checklist has been created');
        navigate(`/start/checklist/${checklistId}/ticket`);
      },
      onError: () => {
        showErrorToast('Checklist Creation Failed');
      },
    }
  );
  const tags = useQuery<Tags[], Error>('labels', fetchTags, {
    onError: () => {
      showErrorToast('Fetching of labels failed');
    },
  });
  return (
    <section>
      <LoadingOverlay
        visible={
          mutation.isLoading || tags.isLoading || locationsQuery.isLoading
        }
      />
      <div className="flex justify-between items-center">
        <Title>Add checklist</Title>
        <Link to="/start" className="text-brand inline-flex items-center">
          Cancel <X />
        </Link>
      </div>
      <Text>Fill the form below to create a new checklist</Text>
      <form
        className="mt-6"
        onSubmit={handleSubmit(
          (data) => mutation.mutate(data),
          (e) => {
            for (const [_, value] of Object.entries(e)) {
              showErrorToast(value.message as string);
            }
          }
        )}
      >
        <Stack>
          <TextInput
            placeholder="Checklist Name"
            {...register('CheckListName', {
              required: 'Name is required',
            })}
          />
          <Controller
            control={control}
            name="LocationId"
            rules={{ required: 'Location is required' }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                data={locationsQuery.data ?? []}
                placeholder="Enter Location"
                onChange={(value) =>
                  onChange(value ? parseInt(value) : undefined)
                }
                value={value?.toString()}
                onBlur={onBlur}
                icon={<MapPin className="text-brand" />}
                searchable
              />
            )}
          />
          <Textarea
            placeholder="Description"
            {...register('Content', {
              required: 'Description is required',
            })}
            minRows={8}
          />
          <div className="flex items-start">
            <span className="mr-3 my-3 font-bold text-lg">Labels</span>
            <ActionIcon
              className="my-3"
              onClick={() =>
                navigate('label', {
                  state: getValues(),
                })
              }
            >
              <CirclePlus className="text-brand" />
            </ActionIcon>
            <Controller
              control={control}
              name="Tags"
              render={({ field: { onChange, value } }) => (
                <>
                  <div className="flex flex-wrap gap-3 ml-3">
                    {value?.map((label) => (
                      <div key={label} className="flex bg-white p-2 rounded">
                        <ActionIcon
                          onClick={() =>
                            onChange(value.filter((x) => x !== label))
                          }
                        >
                          <CircleMinus size={16} className="text-brand" />
                        </ActionIcon>
                        {label}
                      </div>
                    ))}
                  </div>
                </>
              )}
            />
          </div>
          <Button
            fullWidth
            className="bg-brand mt-10 active:bg-brand"
            type="submit"
          >
            Continue
          </Button>
        </Stack>
      </form>
    </section>
  );
}

export default Checklists;
