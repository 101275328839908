import { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { TicketFilterDTO } from 'types/Ticket';
import FilterTickets from './filter';
import TicketLists from './list';

export default function Index() {
  return (
    <Routes>
      <Route path="filter" element={<FilterTickets />} />
      <Route index path="/" element={<TicketLists />} />
    </Routes>
  );
}
