import { createRef } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { css } from '@emotion/react';
import { routes } from 'Mobile/MobileRoutes';
import NavbarTab from 'Mobile/components/NavbarTab';
import AuthProvider from './AuthProvider';

const navCss = css`
  box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.25);
  height: calc(100vw / 4);
`;

function MobileLayout() {
  const location = useLocation();
  const transitionNode = createRef<HTMLDivElement>();
  const navItems = routes
    .filter((route) => route.menuItem.parent === '' && route.path !== 'login')
    .map((route) => (
      <NavbarTab
        className={'flex-1'}
        {...route.menuItem}
        key={route.menuItem.label}
        path={route.path}
      />
    ));

  return location.pathname.includes('login') ? (
    <Outlet />
  ) : (
    <AuthProvider>
      <div className={''}>
        <TransitionGroup component={null}>
          <CSSTransition
            nodeRef={transitionNode}
            key={location.key}
            classNames="fade"
            timeout={300}
          >
            <div ref={transitionNode} className="px-8 pt-4 pb-32 main-content">
              <Outlet />
            </div>
          </CSSTransition>
        </TransitionGroup>
        <nav
          className={
            'fixed bottom-0 w-full flex justify-center items-center bg-gray-100'
          }
          css={navCss}
        >
          {navItems}
        </nav>
      </div>
    </AuthProvider>
  );
}

export default MobileLayout;
