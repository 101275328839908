import { ReactElement, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { refreshToken } from 'Shared/helpers/fetch';
import { UserLoginCredential } from 'types/Users';

function AuthProvider({ children }: { children: ReactElement }) {
  const navigate = useNavigate();
  const expiryTime: string = localStorage.getItem('expireUCTTime') || '';
  const token: string = localStorage.getItem('token') || '';

  const mutation = useMutation<UserLoginCredential | undefined, Error>(
    () => refreshToken(),
    {
      onSuccess: (data) => {
        if (data) {
          localStorage.setItem('token', data.TokenString);
          localStorage.setItem('expireUCTTime', data.ExpireUCTTime);
          localStorage.setItem('refreshTokenString', data.RefreshTokenString);
        }
      },
      onError: () => {
        navigate('/login', { replace: true });
      },
    }
  );

  useEffect(() => {
    if (
      !token ||
      isNaN(Date.parse(expiryTime)) ||
      new Date() >= new Date(expiryTime)
    ) {
      mutation.mutate();
    }
  }, [expiryTime, token]);
  return children;
}
export default AuthProvider;
