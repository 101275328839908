import config from 'config';
import buildQuery from 'odata-query';
import fetchWithAuth from 'Shared/helpers/fetch';

export default async function fetchAllUsers() {
  return await fetchWithAuth(`${config.apiBaseUrl}/Api/Users/-1`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((data) => data.value);
}
